import Modal from 'react-modal';
Modal.defaultStyles.content = {
  ...Modal.defaultStyles.content,
  border: 'none',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  padding: '20px 30px',
  backgroundColor: '#ffffff',
  borderRadius: '15px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.25)',

  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',

  width: 'calc(100% - 20px)',
  fontSize: '14px',
  lineHeight: '17px'
};
Modal.defaultStyles.overlay = {
  ...Modal.defaultStyles.overlay,
  backgroundColor: 'transparent'
};

Modal.setAppElement('#root');
