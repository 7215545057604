import { configure } from 'mobx';

import AuthStore from './modules/auth.store';
import UserStore from './modules/user.store';
import GamesStore from './modules/games.store';
import BetslipStore from './modules/betslip.store';
import MypicksStore from './modules/mypicks.store';
import ChatStore from './modules/chat.store';
import NewStatisticsStore from './modules/newstatistics.store';

import MenuStore from './modules/menu.store';
import AppSizeStore from './modules/app-size.store';
import NetStore from './modules/net.store';

configure({
  enforceActions: 'observed'
});

class Store {
  constructor () {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.gamesStore = new GamesStore(this);
    this.betslipStore = new BetslipStore(this);
    this.mypicksStore = new MypicksStore(this);
    this.chatStore = new ChatStore(this);
    this.newStatisticsStore = new NewStatisticsStore(this);

    // UI
    this.menuStore = new MenuStore(this);
    this.appSizeStore = new AppSizeStore(this);
    this.netStore = new NetStore(this);
  }

  init () {
    this.userStore.init();
    this.gamesStore.init();
    this.chatStore.init();
    this.mypicksStore.init();
  }

  reset () {
    this.userStore.reset();
    this.gamesStore.reset();
    this.chatStore.reset();
    this.mypicksStore.reset();
    this.betslipStore.reset();
  }
}

const store = new Store();
export default store;
