import React from 'react';
import { Link } from 'react-router-dom';

const ErrorIndicator = () => (
  <div className="error-indicator">
    <p>Something went wrong.</p>
    <p>Please try reload page!</p>
    <p><Link to="/">Go to home</Link></p>
  </div>
);

export default ErrorIndicator;
