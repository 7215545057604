const loadIcon = name => require(`@/assets/images/icons/${name}`);

export const icons = {
  arrowWhite:     loadIcon('arrow-down_white.svg'),
  bell:           loadIcon('bell.svg'),
  bellActive:     loadIcon('bell_active.svg'),
  star:           loadIcon('star.svg'),
  starActive:     loadIcon('star_active.svg'),
  arrowRight:     loadIcon('arrow-right.svg'),
  search:         loadIcon('search.svg'),
  bellWhite:      loadIcon('bell-white.svg'),
  starWhite:      loadIcon('star-white.svg'),
  arrowDown:      loadIcon('arrow-down.svg'),
  arrowLeft:      loadIcon('arrow-left_white.svg'),
  send:           loadIcon('send.svg'),
  sendWhite:      loadIcon('send-white.svg'),
  greenCheckMark: loadIcon('green-check-mark.png'),
  envelope:       loadIcon('envelope.svg'),
  close:          loadIcon('close.svg'),
  help:          loadIcon('help.png')
};
