import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import WebFont from 'webfontloader';
import firebase from 'firebase/compat/app';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import 'firebase/compat/analytics';

import ErrorBoundary from '@/components/error-boundary';
import App from '@/App';
import * as serviceWorker from './serviceWorker';
import store from '@/store';
import { history } from '@/_utils/history';
import notifications from '@/_services/notifications';

import firebaseConfig from '@/firebase-config';
import sentryConfig from "@/sentry-config";

import '@/assets/scss/main.scss';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

if (process.env.REACT_APP_ENV === 'production') {
  // @ts-ignore
  firebase.analytics();
} else {
  console.log('%cprocess.env.NODE_ENV', 'color:red', process.env);
}

WebFont.load({
  google: {
    families: ['Montserrat:300,400,500,600:latin']
  }
});


if (sentryConfig.enabled) {
  Sentry.init({
    dsn: sentryConfig.dns,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    autoSessionTracking: false, 
  });
}

document.addEventListener(window.cordova ? 'deviceready' : 'DOMContentLoaded', e => {
  notifications.grantPermission();

  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
        <Router history={history}>
          <App />
        </Router>
      </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
  );

  if (e.type === 'DOMContentLoaded') {
    // for PWA
    serviceWorker.unregister();
  }
});
