import React from 'react';
import { inject, observer } from 'mobx-react';

import icon from './icon.png';
import '@/assets/scss/component/notifications.scss';

const Notification = inject('store')(observer(({store, title, body}) => {
  const { open } = store.chatStore;

  return (
    <div className="notification" onClick={() => open()}>
      <div className="icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="content">
        <h2>{title}</h2>
        <p>{body}</p>
      </div>
    </div>
  );
}));

export default Notification;
