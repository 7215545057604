export default {
  apiKey: "AIzaSyAqJ0Vl3cAegDDn8ySMxhEYwlYBG_KyFqc",
  authDomain: "punteam-225112.firebaseapp.com",
  databaseURL: "https://punteam-225112.firebaseio.com",
  projectId: "punteam-225112",
  storageBucket: "punteam-225112.appspot.com",
  messagingSenderId: "861082645033",
  appId: "1:861082645033:web:2e2f4efe9a8b8fbc862238",
  measurementId: "G-XYV3BQ6X2L"
};
