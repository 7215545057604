import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { inject, observer } from 'mobx-react';
import * as Yup from 'yup';
import Modal from 'react-modal';

import { go } from '@/_utils/history';
import Spinner from '@/components/spinner';
import { icons } from '@/components/Images';

@inject('store')
@observer
class ForgotPassword extends Component {
  componentWillUnmount () {
    this.props.store.authStore.hideForgotPasswordModal()
  }

  render () {
    const { forgotPassword, showModalOnForgotPassword: showModal } = this.props.store.authStore;

    return (
      <div className="auth-inner forgot-password-component">
        <Formik
          initialValues={{
            email: '',
            server_error: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Email is invalid')
              .required('Email is required')
          })}
          onSubmit={forgotPassword}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <div className="form-group">
                <p>Please enter your email address to retrieve your password. Please use the same email address as registered in Punteam</p>
              </div>
              <div className="form-group">
                <Field className="form-text" type="email" name="email" placeholder="Email" autoComplete="off"/>
                <ErrorMessage className="form-message form-message_error" name="email" component="div"/>
              </div>
              <div className="form-group form-group_m0">
                <ErrorMessage className="form-message form-message_error form-message_top text-center" name="server_error" component="div"/>
                <button
                  className={`btn btn_primary btn_xl btn_block ${isSubmitting ? 'in-processing' : ''}`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <span className="text-ellipsis">Submit</span>
                  <Spinner/>
                </button>
              </div>
              <div className="text-right">
                <span
                  className="auth-link"
                  onClick={() => !isSubmitting && go('/login')}
                >
                  Go to Login
                </span>
              </div>
            </Form>
          )}
        </Formik>

        <Modal isOpen={showModal} style={{overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}>
          <div className="modal forgot-password-modal" style={{background: 'white', padding: '20px'}}>
            <div className="icon-holder">
              <img src={icons.greenCheckMark} alt="check" width="50"/>
            </div>
            <p>Thank you. Your password has been sent to your email address.<br/>Please check your inbox.</p>
            <div className="link-box">
              <Link to={{ pathname: "/login" }}>Go to Login</Link>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ForgotPassword;
