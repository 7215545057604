import React from 'react';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';

import Login from '@/views/auth/Login';
import ForgotPassword from '@/views/auth/ForgotPassword';

import logo from '@/assets/images/logo.png';

import '@/assets/scss/component/auth.scss';

const AuthLayout = () => {
  return (
    <div className="auth-layout">
      <div className="desktop-site-button">
        <div className="back-container">
          <a href={process.env.REACT_APP_DESKTOP_APP}>
            <button className="btn btn_primary btn_xl btn_block">
              <span className="text-ellipsis">
                Go to desktop site
              </span>
            </button>
          </a>
        </div>
      </div>
      <div className="auth-logo for-img">
        <img src={logo} alt="logo punteam" width="300" />
      </div>
      <HashRouter basename="/">
        <Switch>
          <Route path="/login" component={Login}/>
          <Route path="/forgot-password" component={ForgotPassword}/>
          <Redirect to="/login"/>
        </Switch>
      </HashRouter>
    </div>
  );
};

export default AuthLayout;
