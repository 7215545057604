export default (error, setFieldError) => {
  if (!error.response) {
    const errorMessage = typeof error === 'string' ? error : (error.message || 'Something went wrong!');
    setFieldError('server_error', errorMessage);
    console.warn(errorMessage);
    return;
  }
  const { message = '', errors = []} = error.response.data;
  errors.forEach(error => setFieldError(error.path, error.message));
  setFieldError('server_error', message);
};
