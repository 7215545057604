import {FILTER_ID, MAX_ID, MIN_ID} from "@/views/betslip/components/BetForm";

const GAMES_INTERVAL = 3000;
const LEAGUES_INTERVAL = 20000;

let websocketData = {}
let leagueData = {}
let intervalId = null
let prepareLeaguesIntervalId = null
let ws = null;

export const initWebsocket = (props) => {
    const userId = localStorage.getItem('userId')
    const idInWhiteList = (((userId >= MIN_ID) && (userId <= MAX_ID)) || (userId == FILTER_ID))
    const wsUrl = idInWhiteList
        ? process.env.REACT_APP_INNER_WEBSOCKET_URL
        : process.env.REACT_APP_PFSTREAM
    ws = new WebSocket(wsUrl);
    ws.onopen = () => {
        console.log("connected websocket main component");

        if (!intervalId) {
            intervalId = setInterval(() => {
            }, GAMES_INTERVAL)
        }

        if (!prepareLeaguesIntervalId) {
            prepareLeaguesIntervalId = setInterval(() => {
                prepareLeagueData()
            }, LEAGUES_INTERVAL)
        }
    };

    ws.onmessage = evt => {
        const data = JSON.parse(evt.data);

        if (data.type === 'init') {
            if (data.events && data.events.length === 1) {
                const value = data.events[0];
                websocketData[value.metadata.event_id] = value;
            } else if (data.data && data.data.events && data.data.events.length) {
                websocketData = data.data.events.reduce((accumulator, value) => {
                    if (!value.metadata.MasterGameId) {
                        return accumulator;
                    }
                    if (!value.snapshot || !value.snapshot.length) {
                        return accumulator;
                    }
                    return {...accumulator, [value.metadata.event_id]: value};
                }, {})
            }
            prepareLeagueData()
            props.store.gamesStore.fetchLeaguesGamesMetaData();
        } else if (data.type === 'change' && data.events && data.events.length) {
            if (data.events && data.events[0] && data.events[0].metadata && data.events[0].metadata.event_id) {
                if (!data.events[0].metadata.MasterGameId) {
                    return;
                }

                if (!websocketData[data.events[0].metadata.event_id]) {
                    websocketData[data.events[0].metadata.event_id] = {}
                }

                websocketData[data.events[0].metadata.event_id] = data.events[0]
                if (!websocketData[data.events[0].metadata.event_id].snapshot
                    || !websocketData[data.events[0].metadata.event_id].snapshot.length) {
                    delete websocketData[data.events[0].metadata.event_id]
                }
            }
        } else if (data.type === 'delete' && data.events && data.events.length) {
            const eventId = data.events[0].event_id;
            if (websocketData[eventId]) {
                delete websocketData[eventId];
            }
        }
    }

    ws.onclose = e => {
        console.log(`Socket is closed`);
        if (intervalId) {
            clearInterval(intervalId)
            intervalId = null;
        }
        if (prepareLeaguesIntervalId) {
            clearInterval(prepareLeaguesIntervalId)
            prepareLeaguesIntervalId = null;
        }
        ws = null;
    };

    ws.onerror = err => {
        console.log("Socket encountered error: " + err.message + "Closing socket");

        ws.close();
        alert('Socket encountered error, please reload the page')
    };
}

export const closeWebsocket = () => {
    if (ws) {}
    ws.close();
}

export const getWebsocketData = () => {
    return websocketData
}

const prepareLeagueData = () => {
    leagueData = {}
    for (const eventId in websocketData) {
        const value = websocketData[eventId];
        leagueData[value.metadata.MasterGameId] = {
            eventId: value.metadata.event_id,
            gameDate: value.metadata.start_time,
            gameId: value.metadata.MasterGameId,
            leagueId: value.metadata.leagueId,
            leagueName: value.metadata.competition_name,
            leagueNameId: value.metadata.leagueNameId,
            market: value.metadata.market,
            team1Name: value.metadata.home,
            team2Name: value.metadata.away
        }
    }
}

export const getLeaguesData = () => {
    return Object.values(leagueData);
}