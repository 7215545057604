import { observable, action, autorun } from 'mobx';

export default class NetStore {
  constructor (rootStore) {
    this.rootStore = rootStore;

    window.addEventListener('online', () => this.toggle(true));
    window.addEventListener('offline', () => this.toggle(false));

    autorun(() => {
      // Log the online status whenever this.isOnline changes
      console.log(`Online status: ${this.isOnline}`);
    });
  }

  @observable isOnline = window.navigator.onLine;

  @action toggle = value => {
    this.isOnline = value;
  };
};
