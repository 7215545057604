import { action, observable } from 'mobx';

export default class MenuStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  @observable isOpen = false;

  @action open = () => {
    this.isOpen = true;
  }

  @action close = () => {
    this.isOpen = false;
  }

  @action toggle = () => {
    this.isOpen = !this.isOpen;
  }
}
