import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './wait-modal.scss';

const WaitModal = ({ opened }) => (
  <Modal
    isOpen={opened}
    style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
  >
    <div className="wait-modal-wrapper">
      <div className="modal pending-modal">
        <span className="modal-title">
          <p>Please wait while checking your pick</p>
          <p>(up to 60 seconds)...</p>
        </span>
      </div>
      <div>
        <span className="modal-text">
          Please note: your pick might rejected if game dropped, or if the score has been changed (goal) within 60 seconds from your submitting.
        </span>
      </div>
    </div>
  </Modal>
);

WaitModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default WaitModal;
