import firebase from 'firebase/compat/app';
import 'firebase/compat/performance';

import config from '@/firebase-config';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
};

const performance = firebase.performance();

export default performance;
