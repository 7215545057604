import React from 'react';
import { inject } from 'mobx-react';

const generateBetLabel = bet => {
  switch (bet.betKey) {
    case 'odds1Total':
      return <>{bet.team1Name} - {bet.team2Name} <b>Over {bet.lineTotal}</b></>;
    case 'odds2Total':
      return <>{bet.team1Name} - {bet.team2Name} <b>Under {bet.lineTotal}</b></>;
    case 'odds1Hcap':
      return <><b>{bet.team1Name}</b> - {bet.team2Name} <b>Home {bet.lineHcap}</b></>;
    case 'odds2Hcap':
      return <>{bet.team1Name} - <b>{bet.team2Name} Away {0 - bet.lineHcap}</b></>;

    //no default
  }
  return '';
};

export default inject('store')(({ id, store: { betslipStore }}) => {
  const bet = betslipStore.bets.find(bet => bet.id === id);
  return (bet ? generateBetLabel(bet) : null);
});
