import axios from 'axios';
import { get as _get } from 'lodash';
import store from '@/store';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const isNetworkError = (errorText) => {
  return errorText.toLowerCase() === 'network error';
}

const errors = [];
const showTip = (code, text) => {
  if (code && text && !errors.find(e => (e.code === code && e.text === text))) {
    const error = { code, text };

    if (isNetworkError(text)) {
      errors.push(error);
      const autoClose = false;
      toast.error(text, {
        autoClose,
        onClose: () => {
          const index = errors.indexOf(error);
          errors.splice(index, 1);
        }
      });
    }
  }
};


http.interceptors.response.use(response => {
  const errorCode = _get(response, 'data.error.errorCode', 0);
  const errorText = _get(response, 'data.error.errorText', '');

  if (!/\/(WebLogin|ForgotPassword)$/.test(response.request.responseURL)) {
    showTip(errorCode, errorText);
  }

  switch (errorCode) {
    case 7:
    case 8:
      // automaticaly logout if Auth wrong
      store.authStore.logout();

    // no default
  }
  return response;
}, error => {
  let errorCode = '';
  let errorText = '';

  if (typeof error.toJSON === 'function') {
    errorText = error.toJSON().message;
    errorCode = 'Error';
  } else {
    errorCode = _get(error, 'response.status');
    errorText = _get(error, 'response.statusText', '');
  }

  showTip(errorCode, errorText);

  if (errorText && errorText.toLowerCase() === 'request failed with status code 504') {
    store.authStore.logout();
  }

  return Promise.reject(error);
});

http.interceptors.request.use(request => {
  const authorizationKey = localStorage.getItem('authorizationKey');
  const userId = localStorage.getItem('userId');
  const isAuthenticated = authorizationKey && userId;
  const isPublic = (/(WebLogin|ForgotPassword)\/?$/gi).test(request.url);

  if (!isPublic && !isAuthenticated) {
    store.authStore.logout();
    return request;
  }

  switch (request.method.toLowerCase()) {
    case 'post':
    case 'put':
    case 'patch':
      // add automaticaly userId and authorizationKey to POST request
      request.data = {
        ...(request.data || {}),
        userId,
        authorizationKey,
        deviceType: isMobile ? 2 : 3 // 2 for mobile web, 3 for desktop web
      }
    // no default
  }

  return request;
});

export default http;
