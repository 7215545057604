import { action, observable, runInAction, computed } from 'mobx';
import moment from 'moment';
import http from '@/_services/http.service';
import performance from '@/_services/perfomance';

const getStatus = status => {
  status = status.toLowerCase();

  switch (true) {
    case status.startsWith('running'):
      return 'status-running';
    case status.startsWith('rejected'):
      return 'status-rejected';
    case status.startsWith('pending'):
      return 'status-pending';
    case status.startsWith('waiting'):
      return 'status-waiting';
    // no default
  }

  return '';
};

export default class MypicksStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  _isDisabled = true;
  autoFetch = false;
  autoFetchTimer = null;
  autoFetchTimeout = 30 * 1000; // 30s

  @observable isLoading;
  @observable picks = [];

  @computed get hasActivePick () {
    return (this.picks || []).some(pick => pick.isLive);
  }

  @action init = () => {
    this._isDisabled = false;
    this.isLoading = false;
    this.picks = [];

    this.runAutoFetch();
  };

  @action reset = () => {
    this._isDisabled = true;
    this.stopAutoFetch();
  };

  @action addPicksList = (list = []) => {
    runInAction(() => {
      this.picks = [...this.picks, ...list];
    });
  }

  @action fetch = async () => {
    clearTimeout(this.autoFetchTimer);
    if (this._isDisabled) return;
    this.isLoading = true;

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMyPicks');
      trace.start();
    }

    try {
      const { data } = await http.post('/GetMyPicks');

      if (process.env.REACT_APP_ENV === 'production') {
        if (data.error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${data.error.errorCode}`);
        }
        trace.stop();
      }

      if (this._isDisabled) return;
      if (process.env.NODE_ENV === 'development') {
        console.log('%cGetMyPicks', 'color: lime', data);
      }

      localStorage.removeItem('localPicks');

      runInAction(() => {
        this.picks = (data.picks || [])
          .map(p => {
            p._date = moment(p.gameDate, "DD/MM/YYYY HH:mm").toDate();
            p._status = getStatus(p.gameStatus);
            return p;
          })
          .filter(p => p._status)
          .filter(p => {
            if (p._status === 'status-rejected') {
              const duration = moment.duration(moment().diff(p._date));
              const hours = duration.asHours();
              return hours < 24;
            }
            return true;
          })
          .sort((a, b) => a._date - b._date)
          .sort((a, b) => {
            if (!a.isLive && b.isLive) return 1;
            if (a.isLive && !b.isLive) return -1;
            return 0;
          });

        const qtyPending = this.picks.filter(p => p._status === 'status-pending').length;
        if (qtyPending === 1) {
          this.picks = this.picks.slice().sort((a, b) => {
            if (a._status !== 'status-pending' && b._status === 'status-pending') return 1;
            if (a._status === 'status-pending' && b._status !== 'status-pending') return -1;
            return 0;
          });
        }

        this.rootStore.chatStore.checkAutoFetch();
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.error(error);
    } finally {
      if (this._isDisabled) return;
      runInAction(() => {
        this.isLoading = false;
      });
      if (this.autoFetch) {
        this.autoFetchTimer = setTimeout(() => this.fetch(), this.autoFetchTimeout);
      }
    }
  };

  @action runAutoFetch = () => {
    this.autoFetch = true;
    this.fetch();
  };

  @action stopAutoFetch = () => {
    this.autoFetch = false;
    clearTimeout(this.autoFetchTimer);
  };
}
