import React from 'react';

export const Shirts = props => {
  return props.home
    ? (
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
           x="0px" y="0px"
           viewBox="0 0 508.949 508.949"
           style={ { enableBackground: 'new 0 0 508.949 508.949' } }
      >
        <g>
          <path fill="#BDC3C7" d="M367.288,231.254c78.102,8.678,86.78-17.356,86.78-17.356V161.83c0-69.424-43.39-86.78-43.39-86.78
  c-32.24-11.116-61.799-28.852-86.78-52.068c0,0-8.678,52.068-69.424,52.068s-69.424-52.068-69.424-52.068
  c-24.98,23.216-54.54,40.951-86.78,52.068c0,0-43.39,17.356-43.39,86.78v52.068c0,0,8.678,26.034,86.78,17.356H367.288z"/>
          <path fill={ props.primaryColor } d="M323.898,22.983c24.98,23.216,54.54,40.951,86.78,52.068c0,0-52.068,130.169-52.068,242.983
  c-0.709,53.504,3.996,106.943,14.041,159.501c1.886,8.786-3.292,17.547-11.897,20.133c-16.644,5.016-48.935,11.281-106.279,11.281
  s-89.635-6.265-106.279-11.281c-8.614-2.586-13.794-11.359-11.898-20.15c10.044-52.552,14.748-105.985,14.041-159.484
  c0-112.814-52.068-242.983-52.068-242.983c32.24-11.116,61.799-28.852,86.78-52.068H323.898z"/>
          <path fill={ props.secondaryColor } d="M410.678,75.05c0,0-13.451,33.671-26.728,81.573c-51.807,42.782-131.992,122.88-239.512,269.971
  c4.044-36.044,6.015-72.291,5.901-108.561c-0.13-20.203-1.55-40.377-4.252-60.399v-0.087
  c80.879-109.95,192.217-188.833,216.428-205.321C377.622,61.691,393.787,69.35,410.678,75.05z"/>
          <path fill={ props.primaryColor } d="M332.576,170.508c-2.799,0.001-5.539-0.8-7.897-2.308l-11.568-7.42
  c-4.098-2.597-6.578-7.115-6.569-11.967v-24.81c0.072-7.923,6.543-14.294,14.466-14.241h23.135
  c7.923-0.053,14.395,6.317,14.466,14.241v24.81c-0.003,4.84-2.481,9.342-6.569,11.932l-11.568,7.42
  C338.119,169.685,335.378,170.498,332.576,170.508z M323.898,147.078l8.678,5.563l8.678-5.563v-19.959h-17.356V147.078z"/>
          <g>
            <path fill={ props.primaryColor } d="M211.085,144.474c-1.343,0-2.668-0.312-3.87-0.911l-30.841-15.421l-30.833,15.464
  c-2.775,1.386-6.078,1.186-8.666-0.524c-2.588-1.71-4.066-4.671-3.879-7.767c0.187-3.096,2.012-5.857,4.787-7.243l34.712-17.356
  c2.442-1.22,5.316-1.22,7.758,0l34.712,17.356c3.555,1.821,5.408,5.834,4.489,9.721S215.079,144.439,211.085,144.474z"/>
            <path  fill={ props.secondaryColor } d="M176.373,161.83c-1.346,0.001-2.674-0.311-3.879-0.911l-34.712-17.356
  c-2.775-1.386-4.599-4.147-4.787-7.243c-0.187-3.096,1.291-6.057,3.879-7.767c2.588-1.71,5.891-1.91,8.666-0.524l30.833,15.421
  l30.833-15.421c2.775-1.386,6.078-1.186,8.666,0.524c2.588,1.71,4.066,4.671,3.879,7.767c-0.187,3.096-2.012,5.857-4.787,7.243
  l-34.712,17.356C179.047,161.519,177.719,161.831,176.373,161.83z"/>
          </g>
          <path fill={ props.primaryColor } d="M323.898,22.983v0.087c-0.521,2.517-10.24,51.981-69.424,51.981s-68.903-49.464-69.424-51.981
		v-0.087l11.802-11.802c2.982-2.969,7.663-3.371,11.108-0.955c14.142,8.2,30.167,12.595,46.514,12.757
		c16.346-0.161,32.372-4.557,46.514-12.757c3.444-2.417,8.126-2.014,11.108,0.955l11.715,11.802H323.898z"/>
          <path fill={ props.secondaryColor } d="M254.474,83.728c-63.887,0-76.661-52.805-77.911-58.828c-0.12-0.603-0.184-1.216-0.191-1.831
  c-0.003-2.327,0.911-4.563,2.543-6.222l11.802-11.802c5.938-5.869,15.199-6.716,22.103-2.022
  c12.704,7.23,27.038,11.112,41.654,11.281c14.578-0.159,28.877-4.013,41.559-11.203c6.911-4.764,16.233-3.944,22.207,1.953
  l11.281,11.359c1.934,1.635,3.051,4.037,3.055,6.569v0.087c-0.002,0.583-0.063,1.165-0.182,1.736
  C331.136,30.923,318.362,83.728,254.474,83.728z M194.666,25.647c3.784,12.392,17.096,40.726,59.809,40.726
  s56.034-28.36,59.8-40.682l-8.34-8.392c-15.58,9.257-33.338,14.213-51.46,14.362c-18.122-0.148-35.882-5.088-51.478-14.319
  L194.666,25.647z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>

    )
    : (
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px"
           y="0px"
           viewBox="0 0 509.016 509.016"
           style={ { enableBackground: 'new 0 0 509.016 509.016' } }>
        <g>
          <g transform="translate(1 1)">
            <path fill={ props.primaryColor } d="M364.43,226.416c76.8,8.533,85.333-17.067,85.333-17.067v-51.2
  c0-68.267-42.667-85.333-42.667-85.333c-31.703-10.931-60.769-28.371-85.333-51.2c0,0-8.533,51.2-68.267,51.2
  s-68.267-51.2-68.267-51.2c-24.564,22.829-53.631,40.269-85.333,51.2c0,0-42.667,17.067-42.667,85.333v51.2
  c0,0,8.533,25.6,85.333,17.067H364.43z"/>
            <path fill={ props.primaryColor } d="M369.72,468.592c1.834,8.633-3.245,17.234-11.691,19.797
  c-16.384,4.949-48.128,11.093-104.533,11.093c-4.437,0-8.704,0-12.8-0.171c-30.928,0.473-61.781-3.201-91.733-10.923
  c-8.446-2.563-13.524-11.164-11.691-19.797c9.891-51.68,14.523-104.229,13.824-156.843c0-110.933-51.2-238.933-51.2-238.933
  c31.703-10.931,60.769-28.371,85.333-51.2h136.533c24.564,22.829,53.631,40.269,85.333,51.2c0,0-51.2,128-51.2,238.933
  C355.198,364.362,359.829,416.911,369.72,468.592z"/>
            <path fill={ props.secondaryColor } d="M344.12,468.592c1.834,8.633-3.245,17.234-11.691,19.797
  c-29.953,7.722-60.805,11.395-91.733,10.923c-30.928,0.473-61.781-3.201-91.733-10.923c-8.446-2.563-13.524-11.164-11.691-19.797
  c9.891-51.68,14.523-104.229,13.824-156.843c0-110.933-51.2-238.933-51.2-238.933c31.703-10.931,60.769-28.371,85.333-51.2
  h110.933c24.564,22.829,53.631,40.269,85.333,51.2c0,0-51.2,128-51.2,238.933C329.598,364.362,334.229,416.911,344.12,468.592z"/>
            <path fill={ props.secondaryColor } d="M321.763,21.616v0.085c-0.512,2.475-10.069,51.115-68.267,51.115s-67.755-48.64-68.267-51.115
  v-0.085l11.605-11.605c2.932-2.92,7.536-3.315,10.923-0.939c13.906,8.063,29.665,12.385,45.739,12.544
  c16.074-0.159,31.833-4.481,45.739-12.544c3.387-2.377,7.991-1.981,10.923,0.939l11.52,11.605H321.763z"/>
            <polygon fill={ props.primaryColor }
                     points="176.696,115.482 142.563,132.549 176.696,149.616 210.83,132.549 		"/>
            <path fill="#A4C2F7" d="M449.763,158.149v51.2c0,0-7.253,21.845-69.035,18.347c38.315-2.901,43.435-18.347,43.435-18.347
  v-51.2c0-68.267-42.667-85.333-42.667-85.333C357.908,64.718,335.736,52.97,315.79,38c2.752-5.123,4.764-10.61,5.973-16.299
  c24.581,22.786,53.644,40.195,85.333,51.115C407.096,72.816,449.763,89.882,449.763,158.149z"/>
            <path fill={ props.secondaryColor } d="M441.23,115.482c-85.333,42.667-190.208,158.379-295.936,303.019
  c0.853-7.339,1.621-15.189,2.389-23.723c1.963-23.296,3.413-51.029,3.413-83.029c-0.128-19.866-1.524-39.704-4.181-59.392v-0.085
  C211.171,164.89,294.968,97.477,337.379,66.245c10.069-7.339,17.835-12.8,22.357-15.872c14.856,9.305,30.751,16.838,47.36,22.443
  C422.366,83.432,434.224,98.254,441.23,115.482z"/>
            <path fill={ props.secondaryColor } d="M415.63,129.392c-77.824,38.912-168.448,149.333-264.533,276.224c1.963-23.296,0-61.867,0-93.867
  c-0.128-19.866-1.524-39.704-4.181-59.392v-0.085C211.171,164.89,294.968,97.477,337.379,66.245
  c13.906,8.432,28.702,15.3,44.117,20.48C396.766,97.341,408.624,112.164,415.63,129.392z"/>
            <polygon fill={ props.primaryColor }
                     points="313.23,115.482 313.23,149.616 330.296,158.149 347.363,149.616 347.363,115.482 		"/>
            <g>
              <path fill="#FFFFFF" d="M127.348,227.696c-39.125-2.714-44.518-18.347-44.518-18.347v-51.2
  c0-68.267,42.667-85.333,42.667-85.333c31.703-10.931,60.769-28.371,85.333-51.2l7.066-7.066
  c-3.561-1.468-6.958-3.304-10.138-5.478c-3.387-2.377-7.991-1.981-10.923,0.939L185.23,21.616
  c-24.564,22.829-53.631,40.269-85.333,51.2c0,0-42.667,17.067-42.667,85.333v51.2C57.23,209.349,64.679,231.416,127.348,227.696z
  "/>
              <path fill={ props.secondaryColor } d="M148.963,488.389c29.953,7.722,60.805,11.395,91.733,10.923c4.096,0.171,8.363,0.171,12.8,0.171
  c4.941,0,9.634-0.06,14.208-0.154c-0.461,0-0.956,0-1.408,0c-30.929,0.467-61.781-3.212-91.733-10.94
  c-8.446-2.563-13.524-11.164-11.691-19.797c5.145-25.584,8.713-51.461,10.684-77.483c0.35-4.386,0.7-8.841,1.007-13.534
  c0.068-1.015,0.128-2.074,0.188-3.106c0.341-5.41,0.649-10.982,0.913-16.777c0-0.563,0.051-1.101,0.077-1.707
  c0.273-6.272,0.486-12.8,0.649-19.507l0.077-3.635c0.137-6.827,0.23-13.858,0.23-21.146c0-4.429-0.111-8.9-0.265-13.372
  l-0.128-3.191c-0.179-3.994-0.393-8.004-0.683-12.015c0-0.478-0.06-0.947-0.094-1.425c-0.341-4.608-0.748-9.222-1.22-13.841
  c-0.094-0.905-0.196-1.818-0.299-2.722c-0.333-3.123-0.708-6.246-1.092-9.387c-1.246-9.882-2.739-19.721-4.437-29.397h-0.316
  c-9.327,1.033-17.613,1.553-25.028,1.707c1.579,9.139,3.012,18.389,4.181,27.708c0.384,3.123,0.759,6.246,1.092,9.387
  c0.102,0.905,0.205,1.818,0.299,2.722c0.466,4.614,0.873,9.227,1.22,13.841c0,0.478,0.06,0.947,0.094,1.425
  c0.29,4.011,0.503,8.021,0.683,12.015l0.128,3.191c0.154,4.471,0.265,8.943,0.265,13.372c0,7.287-0.094,14.293-0.23,21.146
  l-0.077,3.635c-0.162,6.707-0.375,13.235-0.649,19.507c0,0.563-0.051,1.101-0.077,1.707c-0.265,5.794-0.572,11.366-0.913,16.777
  c-0.06,1.033-0.119,2.091-0.188,3.106c-0.307,4.693-0.657,9.148-1.007,13.534c-1.971,26.016-5.539,51.887-10.684,77.466
  C135.439,477.225,140.517,485.826,148.963,488.389z"/>
            </g>
          </g>
          <g>
            <path fill={ props.primaryColor } d="M155.091,243.236c2.807,18.601,4.577,37.343,5.299,56.141c0.538,19.012,0.068,37.385-0.631,56.098
c-0.717,17.348-1.894,34.688-3.627,51.994l-16.981-1.707c1.707-16.936,2.893-33.931,3.558-50.987
c0.691-18.227,1.135-36.847,0.631-54.81c-0.7-18.16-2.41-36.267-5.12-54.238L155.091,243.236z"/>
            <g>
              <path fill={ props.secondaryColor } d="M148.819,320.301c1.026,0.44,2.127,0.678,3.243,0.7c2.261-0.014,4.433-0.886,6.076-2.441
c3.348-3.398,3.348-8.855,0-12.254l-1.297-1.015c-0.483-0.314-1.005-0.564-1.553-0.742c-0.486-0.24-1.008-0.401-1.545-0.478
c-0.565-0.086-1.135-0.129-1.707-0.128c-2.238,0.092-4.364,1.003-5.973,2.56c-0.756,0.809-1.352,1.752-1.758,2.782
c-1.259,3.154-0.554,6.752,1.801,9.199C146.893,319.249,147.812,319.865,148.819,320.301z"/>
              <path fill={ props.secondaryColor } d="M323.531,165.412c4.735,3.026,10.796,3.026,15.531,0l11.375-7.296
c4.03-2.554,6.469-6.996,6.46-11.767v-24.397c-0.07-7.791-6.434-14.055-14.225-14.003h-22.75
c-7.791-0.052-14.155,6.212-14.225,14.003v24.397c0.003,4.759,2.44,9.186,6.46,11.733L323.531,165.412z M322.763,125.016h17.067
v19.627l-8.533,5.47l-8.533-5.47V125.016z"/>
              <path fill={ props.primaryColor } d="M114.498,237.698c6.673,0,14.046-0.324,22.187-1.024c0.486,2.987,1.118,6.118,1.553,9.054
c1.638,11.093,2.961,22.187,3.883,33.357c0.434,4.646,4.483,8.105,9.139,7.808c2.269-0.17,4.376-1.238,5.854-2.969
s2.202-3.979,2.014-6.247c-0.606-7.356-1.434-14.677-2.355-21.982C231.055,155.736,331.876,81.828,360.984,61.57
c13.912,8.206,28.629,14.963,43.921,20.164c11.348,5.831,20.505,15.176,26.103,26.641
c-34.005,19.729-127.019,86.042-273.391,281.463c0.905-11.452,1.707-22.903,2.142-34.364l0.316-8.593
c0.099-3.049-1.436-5.919-4.026-7.529c-2.591-1.61-5.844-1.716-8.533-0.277c-2.69,1.439-4.408,4.203-4.507,7.251l-0.316,8.457
c-0.705,17.033-1.892,34.022-3.558,50.97c-1.98,20.841-5.059,41.563-9.225,62.08c-2.822,12.995,4.855,25.967,17.604,29.747
c17.382,5.214,50.057,11.435,106.982,11.435s89.6-6.221,106.965-11.435c12.752-3.773,20.437-16.742,17.621-29.739
c-9.778-51.104-14.353-103.067-13.653-155.093c0.24-25.506,2.523-50.951,6.827-76.092c8.149,0.717,15.548,1.041,22.246,1.041
c53.06,0,62.831-20.045,64.358-24.653c0.289-0.87,0.436-1.78,0.435-2.697v-51.2c0.332-17.459-2.912-34.802-9.532-50.961
c0-0.06,0-0.119-0.051-0.171l-0.043-0.068c-7.1-18.327-20.797-33.336-38.4-42.078c-0.077,0-0.162,0-0.247,0
c-0.085,0-0.137-0.094-0.222-0.128c-15.895-5.413-31.118-12.628-45.372-21.504l-0.094-0.06
c-13.077-7.928-25.309-17.172-36.506-27.588L317.182,4.969c-5.866-5.836-15.073-6.624-21.845-1.869
c-12.467,7.053-26.518,10.832-40.841,10.982c-14.322-0.151-28.372-3.926-40.841-10.974c-6.766-4.755-15.968-3.975-21.837,1.852
l-11.622,11.622C156.556,38.454,128.635,55.185,98.2,65.717c-0.085,0-0.145,0.102-0.222,0.128s-0.171,0-0.247,0
c-1.954,0.836-48.034,20.096-48.034,93.303v51.2c0.002,0.917,0.152,1.827,0.444,2.697
C51.667,217.653,61.43,237.698,114.498,237.698z M442.23,208.071c-2.79,3.516-16.418,15.838-66.935,11.708
c0.495-2.56,0.853-5.12,1.399-7.731c0.61-2.989-0.422-6.077-2.705-8.099c-2.284-2.023-5.473-2.673-8.367-1.707
c-2.894,0.967-5.052,3.403-5.661,6.393c-7.273,34.24-11.157,69.114-11.597,104.115c-0.706,53.204,3.983,106.342,13.995,158.601
c0.942,4.306-1.585,8.612-5.803,9.89c-16.282,4.881-47.198,10.709-102.059,10.709s-85.777-5.828-102.05-10.709
c-4.221-1.275-6.752-5.582-5.811-9.89c2.202-10.462,5.12-26.803,7.774-48.512c151.74-207.198,247.407-277.342,283.059-298.479
c3.288,11.3,4.892,23.023,4.762,34.79V208.071z M203.902,17.112c15.334,9.053,32.787,13.896,50.594,14.037
c17.823-0.145,35.288-5.019,50.611-14.123l8.243,8.243c-3.191,10.914-15.735,40.013-58.854,40.013
c-42.752,0-55.526-29.116-58.82-40.021L203.902,17.112z M66.763,159.149c0-60.894,35.584-76.681,37.342-77.423
c28.478-9.888,54.942-24.827,78.123-44.1c7.492,17.237,26.59,44.723,72.269,44.723s64.785-27.494,72.269-44.732
c4.702,3.917,10.999,8.806,18.645,14.046c-71.938,51.214-136.596,111.951-192.205,180.548
c-2.654-15.966-5.828-32.427-9.865-49.434c-0.704-2.966-2.938-5.332-5.859-6.205c-2.921-0.873-6.086-0.122-8.303,1.971
c-2.217,2.093-3.149,5.21-2.444,8.176c2.671,11.238,4.966,22.246,7.023,33.05c-51.345,4.233-64.623-8.567-66.995-11.529V159.149z
"/>
              <path fill={ props.secondaryColor } d="M135.32,135.102c0.092,0.583,0.244,1.155,0.452,1.707c0.077,0.188,0.068,0.393,0.154,0.572
c0.186,0.269,0.388,0.525,0.606,0.768c0.31,0.497,0.67,0.96,1.075,1.382c0.41,0.398,0.862,0.75,1.348,1.05
c0.253,0.222,0.518,0.43,0.794,0.623l34.133,17.067c2.401,1.2,5.227,1.2,7.629,0l34.133-17.067c0.085,0,0.128-0.128,0.213-0.171
c0.881-0.466,1.672-1.084,2.338-1.826c0.145-0.162,0.23-0.35,0.367-0.521c0.34-0.407,0.643-0.844,0.905-1.306
c0.085-0.179,0.077-0.384,0.154-0.572c0.209-0.552,0.36-1.124,0.452-1.707c0.274-1.017,0.274-2.089,0-3.106
c-0.092-0.583-0.244-1.155-0.452-1.707c-0.077-0.188-0.068-0.393-0.154-0.572c-0.186-0.269-0.388-0.525-0.606-0.768
c-0.305-0.493-0.659-0.953-1.058-1.374c-0.41-0.398-0.862-0.75-1.348-1.05c-0.253-0.222-0.518-0.43-0.794-0.623l-34.133-17.067
c-2.401-1.2-5.227-1.2-7.629,0l-34.133,17.067c-0.275,0.193-0.54,0.401-0.794,0.623c-0.486,0.3-0.938,0.652-1.348,1.05
c-0.405,0.422-0.766,0.886-1.075,1.382c-0.218,0.243-0.42,0.499-0.606,0.768c-0.085,0.179-0.077,0.384-0.154,0.572
c-0.209,0.552-0.36,1.124-0.452,1.707c-0.274,1.017-0.274,2.089,0,3.106L135.32,135.102z M177.696,126.023l15.053,7.526
l-15.053,7.526l-15.053-7.526L177.696,126.023z"/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
    );
};
