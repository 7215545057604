import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './refresh-modal.scss';

const RefreshModal = ({ opened }) => (
  <Modal
    isOpen={opened}
    style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
  >
    <div className="refresh-modal">
      <div className="title-wrapper">
        <span className="modal-title">Can't confirm pick, please send again</span>
      </div>
      <div className="center">
        <button
          onClick={() => { window.location.reload() }}
          className="btn btn_primary btn_block text-uppercase"
        >
          OK
        </button>
      </div>
    </div>
  </Modal>
);

RefreshModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default RefreshModal;
