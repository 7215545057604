import http from '@/_services/http.service';
import {action, computed, observable, reaction, runInAction} from 'mobx';
import {get as _get} from 'lodash';
import performance from '@/_services/perfomance';
import moment from 'moment';
import {getLeaguesData, getWebsocketData} from "@/helpers/websocket";
import {prepareGamesInfo, prepareLeagueEvents} from "@/helpers/gamesHelper";
import {FILTERED_OUT_LEAGUE_ID_ID, MAX_ID, MIN_ID, FILTER_ID} from "@/views/betslip/components/BetForm";
const GAMES_API_UTC = 1;
const MARKET_LIVE = 2

class Bet {
  constructor (root) {
    this.root = root;
  }

  @observable autoFetch = false;
  autoFetchTimer = null;
  autoLeaguesGamesFetchTimer = null;
  timeout = 3000;

  scrollTargetGame = null;
  scrollTop = 0;

  reactionAutoFetch = reaction(
    () => this.autoFetch,
    isAutoFetch => {
      if (isAutoFetch) {
        this.fetchPicks();
      } else {
        clearTimeout(this.autoFetchTimer);
      }
    }
  );

  @observable league = null;
  @observable games = null;

  @action setLeague = league => {
    this.league = league;
    if (league.isGame) {
      this.scrollTargetGame = {
        team1Name: league.team1Name,
        team2Name: league.team2Name
      };
    }
    const allGames = this.root.allGames;
    this.root.leagueEvents = prepareLeagueEvents(allGames, league);
    console.log('%csetLeague', 'color: yellow', league);
  };

  @action hidePicks = () => {
    this.league = null;
    this.games = null;
    this.scrollTargetGame = null;
    this.scrollTop = 0;
  };

  // @action fetchPicks = async () => {
  //   clearTimeout(this.autoFetchTimer);
  //   if (!this.league) return;
  //
  //   const { league: { leagueName, leagueNameId, market }} = this;
  //   const sport = this.root.selectedSport.id;
  //   const betGamePart = this.root.selectedBetParts.id;
  //
  //   let trace;
  //   if (process.env.REACT_APP_ENV === 'production') {
  //     trace = performance.trace('GamesByLeague');
  //     trace.start();
  //   }
  //
  //   try {
  //     const { data: { games, error = {}}} = await http.post('/GamesByLeague', {
  //       sport,
  //       betGamePart,
  //       market,
  //       leagueNameId
  //     });
  //
  //     if (process.env.REACT_APP_ENV === 'production') {
  //       if (error.errorCode !== 0) {
  //         trace.putAttribute('errorCode', `${error.errorCode}`);
  //       }
  //       trace.stop();
  //     }
  //
  //     if (process.env.NODE_ENV === 'development') {
  //       console.log('%cGamesByLeague', 'color:lime', games);
  //     }
  //
  //     const utcOffset = moment().utcOffset() / 60;
  //     const tmpGamesList = games.map(game => ({
  //       ...game,
  //       eventDateTime: moment.utc(game.eventDateTime).add(utcOffset - GAMES_API_UTC, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
  //       gameDate: moment.utc(game.eventDateTime).add(utcOffset - GAMES_API_UTC, 'hours').format('DD/MM/YYYY HH:mm'),
  //     }))
  //
  //     const gamesList = filterGamesForMarket(tmpGamesList, market);
  //     runInAction(() => {
  //       this.games = this.transformGames(gamesList, sport, market, leagueName);
  //     })
  //   } catch (error) {
  //     if (process.env.REACT_APP_ENV === 'production') {
  //       trace.putAttribute('error', error.message);
  //       trace.stop();
  //     }
  //     console.warn(error);
  //   } finally {
  //     if (this.autoFetch) {
  //       this.autoFetchTimer = setTimeout(() => {
  //         this.fetchPicks();
  //       }, market === 2 ? this.timeout : this.timeout);
  //     }
  //   }
  // };


  @action fetchPicks = async () => {
    clearTimeout(this.autoFetchTimer);
    if (!this.league) return;

    const sport = this.root.selectedSport.id;
    const { league } = this;
    try {
      const utcOffset = moment().utcOffset() / 60;
      const leagueEvents = this.root.leagueEvents || []
      const websocketData = getWebsocketData()
      const {gamesWithOdds} = prepareGamesInfo({
        league, leagueEvents, websocketData, utcOffset
      })
      runInAction(() => {
        this.games = this.transformGames(gamesWithOdds, sport, league.market, league.leagueName);
      })
    } catch (error) {
      console.warn(error);
    } finally {
      if (this.autoFetch) {
        this.autoFetchTimer = setTimeout(() => {
          this.fetchPicks();
        }, this.timeout);
      }
    }
  };


  @action toggleFavorite = () => {
    if (!this.league) return;
    this.league.isFavorite = !this.league.isFavorite;
    this.root.toggleFavorite(this.league);
  };

  transformGames = (games, sport, market, leagueName) => {
    const prepareOdds = (odd) => odd < 1 ? 1 : odd

    const gamesMap = games
      .reduce((res, game) => {
        const pick = {
          ...game,
          odds1Hcap: Number(prepareOdds(game.odds1Hcap).toFixed(2)),
          odds1Total: Number(prepareOdds(game.odds1Total).toFixed(2)),
          odds2Hcap: Number(prepareOdds(game.odds2Hcap).toFixed(2)),
          odds2Total: Number(prepareOdds(game.odds2Total).toFixed(2)),
          sport,
          market,
          leagueName
        };
        const oldGame = (this.games || []).find(g => g.id === game.gameId);
        return {
          ...res,
          [game.gameId]: {
            isOpened: oldGame ? oldGame.isOpened : true,
            id: game.gameId,
            picks: res[game.gameId] ? [...res[game.gameId].picks, pick] : [pick],
            team1Name: game.team1Name,
            team2Name: game.team2Name
          }
        };
      }, {});

    return Object.values(gamesMap)
      .map(game => {
        game.picks = game.picks.slice().sort((a, b) => a.lineHcap - b.lineHcap);
        return game;
      })
      .sort((a, b) => {
        const dateA = new Date(a.picks[0].eventDateTime).getTime();
        const dateB = new Date(b.picks[0].eventDateTime).getTime();
        return dateA - dateB;
      });
  };
}

export default class GamesStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  init = () => {};

  reset = () => {
    runInAction(() => {
      this.autoFetchLeaguesGamesMetaData = false;
      this.bet.hidePicks();
      this.markets = {
        isLoading: false,
        data: []
      };
      this.filterTabs = [
        {
          label: 'All Games',
          value: 'all-games',
          isSelected: true
        },
        {
          label: 'Favorites',
          value: 'favorites'
        }
      ];
      this.filterSports = {
        isLoading: false,
        data: []
      };
      this.filterLeagues = {
        isLoading: false,
        data: []
      };
      this.filterBetParts = {
        isLoading: false,
        data: []
      };
      this.scrollTop = 0;
    })
  };

  // const
  autoFetchTimer = null;
  autoFetchTimeout = 30 * 1000; // 30s
  @observable autoFetchLeaguesGamesMetaData = false;

  scrollTop = 0;

  // OBSERVABLE
  @observable bet = new Bet(this);

  // filter
  @observable filterTabs = [
    {
      label: 'All Games',
      value: 'all-games',
      isSelected: true
    },
    {
      label: 'Favorites',
      value: 'favorites'
    }
  ];
  @observable filterSports = {
    isLoading: false,
    data: []
  };
  @observable filterLeagues = {
    isLoading: false,
    data: []
  };
  // league events
  @observable leagueEvents = [];

  // all games
  @observable allGames = [];

  @observable filterBetParts = {
    isLoading: false,
    data: []
  };

  // markets
  @observable markets = {
    isLoading: false,
    data: []
  };

  // COMPUTED
  @computed get selectedTab () {
    return this.filterTabs.find(item => item.isSelected);
  }
  @computed get selectedSport () {
    return this.filterSports.data.find(item => item.isSelected);
  }
  @computed get selectedLeague () {
    return this.filterLeagues.data.find(item => item.isSelected);
  }
  @computed get selectedBetParts () {
    return this.filterBetParts.data.find(item => item.isSelected);
  }
  @computed get selectedMarket () {
    return this.markets.data.find(item => item.isSelected);
  }
  @computed get shownMarketLeagues () {
    if (!this.selectedMarket) return [];

    const isFavorite = this.selectedTab.value === 'favorites';
    const selectedLeagueId = this.selectedLeague ? this.selectedLeague.leagueId : 0;

    return this.getLeaguesFromGames(
      this.getGamesFromMarkets(this.selectedMarket)
        .filter(league => {
          if (isFavorite) {
            return league.isFavorite;
          }
          return true;
        })
        .filter(league => {
          if (selectedLeagueId != 0) { // eslint-disable-line eqeqeq
            return league.leagueId == selectedLeagueId; // eslint-disable-line eqeqeq
          }
          return true;
        })
    );
  }
  @computed get searchData () {
    const allGames = this.getGamesFromMarkets();
    const leagues = allGames.reduce((res, game) => {
      if (res.find(item => item.leagueId === game.leagueId)) {
        return res;
      }
      return [...res, { ...game, isLeague: true }];
    }, []);
    const games = allGames.reduce((res, game) => {
      if (res.find(item => {
        return item.leagueId === game.leagueId &&
        item.team1Name === game.team1Name &&
        item.team2Name === game.team2Name
      })) {
        return res;
      }
      return [...res, { ...game, isGame: true }];
    }, []);

    return {
      leagues,
      games
    };
  }

  // autoruns
  autorunFetchForSelectedMarket = reaction(
    () => this.selectedMarket,
    () => this.fetchLeaguesGamesMetaData()
  );
  autorunFetchLeaguesGamesMetaData = reaction(
    () => this.autoFetchLeaguesGamesMetaData,
    isAutoFetch => {
      if (isAutoFetch) {
        if (this.markets.data.length) {
          this.fetchLeaguesGamesMetaData();
        }
      } else {
        clearTimeout(this.autoLeaguesGamesFetchTimer);
      }
    }
  );
  autorunFetchForBetParts = reaction(
    () => this.selectedBetParts,
    () => this.fetchLeaguesGamesMetaData()
  );

  /**
   * fetchPageInfo
   */
  @action fetchPageInfo = async () => {
    this.filterSports.isLoading = true;
    this.filterBetParts.isLoading = true;
    this.markets.isLoading = true;

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetCreatePickPageInfo');
      trace.start();
    }

    try {
      const { data: { error, ...PickPageInfo }} = await http.post('/GetCreatePickPageInfo');

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('%cGetCreatePickPageInfo', 'color: lime', PickPageInfo, error);
      // }

      runInAction(() => {
        this.filterSports.data = (PickPageInfo.sports || []).map((item, index) => ({
          ...item,
          isSelected: index === 0
        }));
        this.filterBetParts.data = (PickPageInfo.betGameParts || []).map((item, index) => ({
          ...item,
          isSelected: index === 0
        }));
        this.markets.data = (PickPageInfo.marketTypes || []).map(item => ({
          ...item,
          games: [],
          isLoading: false,
          isSelected: item.id == 2 // eslint-disable-line eqeqeq
        }));
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    } finally {
      runInAction(() => {
        this.filterSports.isLoading = false;
        this.filterBetParts.isLoading = false;
        this.markets.isLoading = false;
      });
    }
  };

  /**
   * get games for market
   */
  @action fetchLeaguesGamesMetaData = async () => {
    clearTimeout(this.autoLeaguesGamesFetchTimer);

    const selectedMarket = this.selectedMarket;
    const sport = this.selectedSport;
    const betGamePart = this.selectedBetParts;

    if (!sport || !betGamePart) return;

    if (selectedMarket) {
      selectedMarket.isLoading = true;
    }

    let trace;
    // if (process.env.REACT_APP_ENV === 'production') {
    //   trace = performance.trace('LeaguesGamesMetaData');
    //   trace.start();
    // }

    let preparedGames = [];
    try {
      // const { data: { games, error }} = await http.post('/LeaguesGamesMetaData', {
      //   sport: sport.id,
      //   betGamePart: betGamePart.id,
      //   market: selectedMarket ? selectedMarket.id : null
      // });

      // if (process.env.REACT_APP_ENV === 'production') {
      //   if (error.errorCode !== 0) {
      //     trace.putAttribute('errorCode', `${error.errorCode}`);
      //   }
      //   trace.stop();
      // }
      //
      // if (process.env.NODE_ENV === 'development') {
      //   console.log('%cLeaguesGamesMetaData', 'color: lime', games, error);
      // }

      const utcOffset = moment().utcOffset() / 60;
      const leaguesData = getLeaguesData()

      const localUserId = localStorage.getItem('userId')
      const idInWhiteList = (((localUserId >= MIN_ID) && (localUserId <= MAX_ID)) || (localUserId == FILTER_ID))

      preparedGames = leaguesData
          .map(g => ({
            ...g,
            gameDate: moment.utc(g.gameDate).add(utcOffset - GAMES_API_UTC, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
          })).filter(v => idInWhiteList ? v: v.leagueId !== FILTERED_OUT_LEAGUE_ID_ID)

      runInAction(() => {
        // if (error.errorCode === 0) {
          this.markets.data.forEach(market => {
            market.games = preparedGames.filter(game => game.market === market.id)
          });
          this.allGames = preparedGames;
        // } else if (selectedMarket) {
        //   selectedMarket.games = [];
        // }
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    } finally {
      runInAction(() => {
        if (selectedMarket) {
          selectedMarket.isLoading = false;
        }

        const selectedLeagueId = _get(this, 'selectedLeague.leagueId', 0);
        const selectedLeagueName = _get(this, 'selectedLeague.leagueName', '');
        const leagues = [
          {
            leagueName: 'All Leagues',
            leagueId: 0,
            leagueNameId: 0
          },
          ...this.getLeaguesFromGames(this.getGamesFromMarkets(selectedMarket))
        ];
        let indexOf = leagues.findIndex(league => (
          league.leagueId === selectedLeagueId &&
          league.leagueName === selectedLeagueName
        ));

        if (indexOf < 0) indexOf = 0;
        leagues[indexOf].isSelected = true;
        this.filterLeagues.data = leagues;

        const league = leagues[indexOf];
        let leagueEvents = this.leagueEvents || []
        if (league.leagueId) {
          leagueEvents = prepareLeagueEvents(preparedGames, league)
        }
        this.leagueEvents = leagueEvents;
      });
    }
    if (this.autoFetchLeaguesGamesMetaData) {
      this.autoLeaguesGamesFetchTimer = setTimeout(() => {
        this.fetchLeaguesGamesMetaData()
      }, this.autoFetchTimeout);
    }
  };

  /**
   * set selected item
   */
  @action setSelected = (prop, value) => {
    const items = _get(this, prop, []);
    // @ts-ignore
    items.forEach(item => item.isSelected = item === value);
  };

  /**
   * toggleFavorite
   */
  @action toggleFavorite = async league => {
    const { leagueNameId, isFavorite } = league;

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetUserFavoritesLeagues');
      trace.start();
    }

    try {
      const { data: { error }} = await http.post('/SetUserFavoritesLeagues', {
        leagueNameId,
        isFavorite: !isFavorite
      });
      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    }

    runInAction(() => {
      const selectedGames = _get(this, 'selectedMarket.games', []);
      selectedGames.forEach(game => {
        if (game.leagueNameId === leagueNameId)  {
          game.isFavorite = !isFavorite;
        }
      });
    });
  };


  // getGamesFromMarkets = market => {
  //   if (market) {
  //     return prepareGamesForMarket(market.games, market.id);
  //   }
  //   return this.markets.data.reduce((res, market) => {
  //     return [...res, ...prepareGamesForMarket(market.games, market.id)];
  //   }, [])
  // };


  filterTodayGames = (list, marketId) => {
    const utcOffset = moment().utcOffset() / 60;
    return marketId !== MARKET_LIVE ? list.filter(g => {
      const date = moment(g.eventDateTime || g.gameDate).add(Math.abs(utcOffset - GAMES_API_UTC), 'hours')
      return moment().isBefore(date)
    }) : list
  }


  getGamesFromMarkets = market => {
    if (market) {
      return market.games
      // return filterGamesForMarket(market.games, market.id);
    }
    return this.markets.data.reduce((res, market) => {
      // return [...res, ...filterGamesForMarket(market.games, market.id)];
      return [...res, ...market.games];
    }, [])
  };

  getLeaguesFromGames = games => {
    return games
      .reduce((res, game) => {
        if (res.some(resGame => resGame.leagueNameId === game.leagueNameId)) {
          return res;
        }
        return [...res, {
          isFavorite: game.isFavorite,
          leagueId: game.leagueId,
          leagueName: game.leagueName,
          leagueNameId: game.leagueNameId,
          market: game.market
        }];
      }, [])
      .sort((a, b) => {
        if (a.leagueName > b.leagueName) return 1;
        if (a.leagueName < b.leagueName) return -1;
        return 0;
      });
  };
}

// const filterGamesForMarket = (list, marketId) => {
//   let result = [];
//
//   const momentUtc = moment().utc(true);
//
//   switch (Number(marketId)) {
//     case MARKET_TYPE.live:
//       result = list;
//       break;
//
//     case MARKET_TYPE.today: {
//       result = list.filter((g) => {
//         const date = moment.utc(g.eventDateTime || g.gameDate);
//         return momentUtc.isBefore(date)
//       });
//       break;
//     }
//
//     case MARKET_TYPE.early: {
//       result = list.filter((g) => {
//         const date = moment.utc(g.eventDateTime || g.gameDate);
//         return momentUtc.isBefore(date)
//       });
//       break;
//     }
//
//     default:
//   }
//
//   return result;
// }
