import React, { Component } from 'react';
import loadable from '@loadable/component'
import Spinner from '@/components/spinner';

const LoadableComponent = loadable(() => import('./DashboardLayout'), {
  fallback: <Spinner className="center-page"/>
});

export default class LoadableDashboard extends Component {
  render () {
    return <LoadableComponent/>;
  }
};
