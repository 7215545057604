import { sendTokenToServer, writeTokenToStorage, showUINotification } from './index';

export default class CordovaNotifications {
  static type = 'CordovaNotifications';

  async getToken () {
    const hasPermission = await this.requestPermission();

    if (hasPermission) {
      return new Promise(resolve => {
        // @ts-ignore
        window.FirebasePlugin.getToken(token => {
          resolve(token);
        }, error => {
          console.warn(error);
          resolve(null);
        });
      });
    } else {
      return null;
    }
  }

  async grantPermission () {
    return new Promise(resolve => {
      // @ts-ignore
      window.FirebasePlugin.grantPermission(resolve);
    });
  }

  requestPermission () {
    return new Promise(resolve => {
      // @ts-ignore
      window.FirebasePlugin.hasPermission(hasPermission => {
        resolve(hasPermission);
      });
    });
  }

  setAutoInitEnabled (state) {
    return new Promise((resolve, reject) => {
      window.FirebasePlugin.setAutoInitEnabled(state, () => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  async subscribe () {
    console.log('%cSUBSCRIBE', 'color:red');
    await this.setAutoInitEnabled(true);
    const hasPermission = await this.requestPermission();
    console.log('%cSUBSCRIBE hasPermission', 'color:red', hasPermission);
    if (!hasPermission) {
      // TODO: show message for user about important notifications for this app
      console.warn('It\'s important to allow notification\nfor get actual bets status');
      return;
    }

    try {
      const token = await this.getToken();
      if (token) {
        sendTokenToServer(token);
      } else {
        writeTokenToStorage(null);
      }
    } catch (error) {
      console.warn(error);
      writeTokenToStorage(null);
    }

    // @ts-ignore
    window.FirebasePlugin.onMessageReceived(data => {
      console.log('onMessageReceived', data);
      if (data.messageType === 'notification') {
        showUINotification(data.aps.alert, !!data.tap);
      }
    }, error => {
      console.error(error);
    });

    // @ts-ignore
    window.FirebasePlugin.onTokenRefresh(token => {
      // Send Instance ID token to app server.
      sendTokenToServer(token);
    }, error => {
      console.error('Failed to refresh token', error);
    });
  }

  async unsubscribe () {
    await this.setAutoInitEnabled(false);
    // @ts-ignore
    window.FirebasePlugin.clearAllNotifications();
    window.FirebasePlugin.setBadgeNumber(0);
    window.FirebasePlugin.unregister();
    writeTokenToStorage(null);
  }
}
