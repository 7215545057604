import { action, observable, computed, runInAction } from 'mobx';
import http from '@/_services/http.service';

import { go } from '@/_utils/history';
import handleFormError from '@/_utils/handle-form-error';

import notifications from '@/_services/notifications';
import performance from '@/_services/perfomance';

if (localStorage.getItem('tiltMode') === null) {
  localStorage.setItem('tiltMode', '1');
}

export default class UserStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  @observable deviceToken = localStorage.getItem('firebaseToken');

  @observable showModalOnForgotPassword = false;
  @observable credentials = {
    userId: null,
    authorizationKey: null
  };
  @observable tiltMode = localStorage.getItem('tiltMode');

  @computed get isSignedIn () {
    return !!this.credentials.userId && !!this.credentials.authorizationKey && !!localStorage.getItem('authorizationKey');
  }

  @action login = async ({ email, password }, { setSubmitting, setFieldError }) => {
    let deviceToken = null;

    try {
      deviceToken = await notifications.getToken();
      this.deviceToken = deviceToken;
      console.log('%cdeviceToken', 'color:red', deviceToken);
    } catch (error) {
      console.warn(error);
    }

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }

    try {
      const { data: { userId, authorizationKey, tiltMode = true, error }} = await http.post('/WebLogin', {
        userEmail: email,
        userPassword: password,
        deviceToken,
        notificationType: window.cordova ? 1 : 2
      });

      if (error.errorCode !== 0) {
        if (process.env.REACT_APP_ENV === 'production') {
          trace.putAttribute('errorCode', `${error.errorCode}`);
          trace.stop();
        }
        throw error.errorText;
      }

      if (process.env.REACT_APP_ENV === 'production') {
        trace.stop();
      }
      localStorage.setItem('userId', userId);
      localStorage.setItem('authorizationKey', authorizationKey);
      localStorage.setItem('tiltMode', tiltMode ? '1' : '0');

      runInAction(() => {
        this.tiltMode = tiltMode ? '1' : '0';
        this.credentials = {
          userId: userId,
          authorizationKey: authorizationKey
        };
      });
      go('/');
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && typeof error !== 'string') {
        trace.putAttribute('errorCode', error.code);
        trace.stop();
      }
      handleFormError(error, setFieldError);
    } finally {
      setSubmitting(false);
    }
  };

  @action logout = async () => {
    notifications.unsubscribe();
    localStorage.removeItem('authorizationKey');
    localStorage.removeItem('userId');
    localStorage.removeItem('tiltMode');
    this.rootStore.chatStore.clearNotifications();
    this.credentials = {
      userId: null,
      authorizationKey: null
    };
  }

  @action forgotPassword = async ({ email }, { setSubmitting, setFieldError }) => {
    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('ForgotPassword');
      trace.start();
    }

    try {
      const { data: { error }} = await http.post('/ForgotPassword', { email });

      if (error && error.errorCode !== 0) {
        if (process.env.REACT_APP_ENV === 'production') {
          trace.putAttribute('errorCode', `${error.errorCode}`);
          trace.stop();
        }
        throw error.errorText;
      }

      if (process.env.REACT_APP_ENV === 'production') {
        trace.stop();
      }
      runInAction(() => this.showModalOnForgotPassword = true);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && typeof error !== 'string') {
        trace.putAttribute('errorCode', `${error.code || error.message}`);
        trace.stop();
      }
      handleFormError(error, setFieldError);
    } finally {
      setSubmitting(false);
    }
  }

  @action checkAuthorize = () => {
    this.credentials = {
      userId: localStorage.getItem('userId'),
      authorizationKey: localStorage.getItem('authorizationKey')
    };
  }

  @action hideForgotPasswordModal () {
    this.showModalOnForgotPassword = false;
  }
}
