export const CORNER_RAW = 'corner';
export const CORNER = 'CORNER';
export const GOAL_RAW = 'goal';
export const GOAL = 'GOAL';
export const YC_RAW = 'yellow card';
export const YC = 'YELLOW_CARD';
export const RC_RAW = 'red card';
export const RC= 'RED_CARD';
export const PENALTY_RAW = 'penalty';
export const PENALTY = 'PENALTY';

export const GET_LEAGUE_ERROR = 'Options error: required option "gamesInPLay" is empty or undefined';
export const NAME = 'NA';
export const LEAGUE = 'CT';
export const RELATED_LEAGUE = 'CT'
export const SPORT = 'CL';
export const ID = 'ID';
export const EVENT_ID = 'ID'
export const SOCCER = 'Soccer';
export const COLOR_KIT = 'KC';
export const TEAM = 'TE';

export const setOfDefaultColors = {
  primaryColorHome: '#EB543E',
  secondaryColorHome: '#366fb1',
  primaryColorAway: '#FDD92A',
  secondaryColorAway: '#50c14e'
};

export const getSoccerData = (gamesInPlay = []) => {
  if (!gamesInPlay.length) {
    throw Error(GET_LEAGUE_ERROR);
  }

  const sportID = gamesInPlay.find(item => (
    item.type === SPORT && item[NAME] === SOCCER
  ))[ID];

  const leaguesList = gamesInPlay.filter(item => (
    item.type === LEAGUE && item[ID] === sportID
  ));

  return leaguesList.map(league => ({
    name: league[NAME],
    games: gamesInPlay.filter(game => (
      game[RELATED_LEAGUE] === league[NAME]
    )).map(game => ({
      id: parseInt(game[EVENT_ID], 10),
      name: game[NAME]
    })).sort()
  }));
};

export const getSelectedMatch = (selectedMatchResults, selectedEventStats, filteredTime) => {
  const getHistoryEventType = ({ events = [], home, away }) => {
    const history = events.map(event => {
      const { text } = event;
      const lowercaseText = text.toLowerCase();

      event.time = parseInt(lowercaseText);
      if (lowercaseText.includes(home.name.toLowerCase())) {
        event.team = 'home';
      } else if (lowercaseText.includes(away.name.toLowerCase())) {
        event.team = 'away';
      } else {
        event.team = null;
      }

      if (lowercaseText.indexOf(CORNER_RAW) !== -1) {
        event.type = CORNER;
        return event;
      } else if (lowercaseText.indexOf(GOAL_RAW) !== -1) {
        event.type = GOAL;
        return event;
      } else if (lowercaseText.indexOf(YC_RAW) !== -1) {
        event.type = YC;
        return event;
      } else if (lowercaseText.indexOf(RC_RAW) !== -1) {
        event.type = RC;
        return event;
      } else if (lowercaseText.indexOf(PENALTY_RAW) !== -1) {
        event.type = PENALTY;
        return event;
      } else {
        return event;
      }
    });

    return history;
  };

  const getFilteredEvents = ({ events, home, away }) => {
    const filteredEvents = {
      filteredCorners: [0, 0],
      filteredGoals:  [0, 0],
      filteredPenalties:  [0, 0],
      filteredRedcards:  [0, 0],
      filteredYellowcards:  [0, 0]
    };

    (events || []).map(event => {
      const { text } = event;
      const lowercaseText = text.toLowerCase();

      event.time = parseInt(lowercaseText);

      if (lowercaseText.includes(home.name.toLowerCase())) {
        event.team = 'home';
      } else if (lowercaseText.includes(away.name.toLowerCase())) {
        event.team = 'away';
      } else {
        event.team = null;
      }

      if (lowercaseText.indexOf(CORNER_RAW) !== -1) {
        event.type = CORNER;
        return event;
      } else if (lowercaseText.indexOf(GOAL_RAW) !== -1) {
        event.type = GOAL;
        return event;
      } else if (lowercaseText.indexOf(YC_RAW) !== -1) {
        event.type = YC;
        return event;
      } else if (lowercaseText.indexOf(RC_RAW) !== -1) {
        event.type = RC;
        return event;
      } else if (lowercaseText.indexOf(PENALTY_RAW) !== -1) {
        event.type = PENALTY;
        return event;
      } else {
        return event;
      }
    }).filter(h => h.time >= filteredTime[0] && h.time <= filteredTime[1])
      .forEach(g => {
      if (g.type === 'RED_CARD' && g.team) filteredEvents.filteredRedcards[g.team && g.team === 'away' ? 1 : 0] += 1
      if (g.type === 'CORNER' && g.team && !g.text.match(/Race to/)) filteredEvents.filteredCorners[g.team && g.team === 'away' ? 1 : 0] += 1
      if (g.type === 'GOAL' && g.team) {
        filteredEvents.filteredGoals[g.team === 'away' ? 1 : 0] += 1
        if(!!g.text.match(/Penalty/)) filteredEvents.filteredPenalties[g.team && g.team === 'away' ? 1 : 0] += 1
      }
      if (g.type === 'PENALTY' || (!!g.text.match(/Penalty/) && g.team)) filteredEvents.filteredPenalties[g.team && g.team === 'away' ? 1 : 0] += 1
      if (g.type === 'YELLOW_CARD' && g.team) filteredEvents.filteredYellowcards[g.team && g.team === 'away' ? 1 : 0] += 1
    });
    return filteredEvents;
  };

  return {
      ...selectedMatchResults,
      selectedEventStats,
      history: getHistoryEventType(selectedMatchResults),
      filteredHistory: getFilteredEvents(selectedMatchResults)
  }
};

export const getColors = (teamStats) => {
  if (
    teamStats.length &&
    teamStats[0][COLOR_KIT] &&
    teamStats[1] &&
    teamStats[1][COLOR_KIT]
  ) {
    return {
      primaryColorHome: teamStats[0][COLOR_KIT].split(',')[0],
      secondaryColorHome:teamStats[0][COLOR_KIT].split(',')[1] || teamStats[0][COLOR_KIT].split(',')[0],
      primaryColorAway: teamStats[1][COLOR_KIT].split(',')[0],
      secondaryColorAway: teamStats[1][COLOR_KIT].split(',')[1] || teamStats[1][COLOR_KIT].split(',')[0]
    }
  } else {
    return setOfDefaultColors
  }
}

export const getShots = (on_target, off_target) => {
  if (on_target && off_target) {
    return {
      homeShots: +on_target[0] + +off_target[0],
      awayShots: +on_target[1] + +off_target[1]
    }
  } else {
    return {
      homeShots: null,
      awayShots: null
    }
  }
};

export const formatTime = (before, after) => {
  const pad2 = number => ((number < 10 ? '0' : '') + number);

  return `${pad2(before)} : ${pad2(after)}`;
};

export const getScores = (score) => {
  try {
    const homeScore = score.substring(0, score.indexOf('-')).replace('-', '');
    const awayScore = score.substring(score.indexOf('-'), score.length).replace('-', '');

    return {
      homeScore,
      awayScore
    };
  } catch (e) {
    return {
      homeScore: 0,
      awayScore: 0
    };
  }
};
