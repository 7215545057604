import React from 'react';

import './no-connection.scss';

const NoConnection = () => {
  return (
    <div className="no-connection">
      No connection to network
    </div>
  );
};

export default NoConnection;
