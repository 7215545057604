import { action, observable, computed, runInAction } from 'mobx';
import http from '@/_services/http.service';
import performance from '@/_services/perfomance';

export default class UserStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  _isDisabled = true;
  autoFetch = false;
  autoFetchTimer = null;
  autoFetchTimeout = 1 * 60 * 1000; // 1m

  /**
   * @observable
   */
  @observable user;
  @observable traderStatus;
  @observable profileDetails;
  @observable balance = 0;

  /**
   * @computed
   */
  @computed get unitLeft () {
    if (this.user && this.user.unitLeft > 0) {
      return this.user.unitLeft;
    }
    return 0;
  }

  @action init = () => {
    this._isDisabled = false;
    this.user = null;
    this.traderStatus = null;
    this.profileDetails = null;
    this.balance = 0;

    this.runAutoFetch();
  };

  @action reset = () => {
    this._isDisabled = true;
    this.stopAutoFetch();
  }

  getWithTrace = async path => {
    let q, trace;

    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace(path);
      trace.start();
    }

    try {
      q = await http.post(`/${path}`);

      if (process.env.REACT_APP_ENV === 'production') {
        if (q.data.error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${q.data.error.errorCode}`);
        }
        trace.stop();
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      throw error;
    }

    return q;
  };

  /**
   * fetch
   */
  @action fetch = async () => {
    clearTimeout(this.autoFetchTimer);

    if (this._isDisabled) return;

    try {
      const [{ data: WebTemplate }, { data: MyFinancePageInfo }] = await Promise.all([
        this.getWithTrace('GetWebTemplate'),
        this.getWithTrace('GetMyFinancePageInfo')
      ]);

      if (this._isDisabled) return;
      // if (process.env.NODE_ENV === 'development') {
      //   console.log('%cGetWebTemplate', 'color: #bada55', WebTemplate);
      // }
      runInAction(() => {
        this.user = WebTemplate.user; // get unitLeft
        this.traderStatus = WebTemplate.traderStatus;
        this.balance = MyFinancePageInfo.balance;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      if (this._isDisabled) return;
      if (this.autoFetch) {
        this.autoFetchTimer = setTimeout(() => this.fetch(), this.autoFetchTimeout);
      }
    }
  };

  @action fetchProfileDetails = async () => {
    if (this._isDisabled) return;

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetEditProfileDetails');
      trace.start();
    }

    try {
      const { data } = await http.post('/GetEditProfileDetails');
      const { error, ...ProfileDetails } = data;

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      if (this._isDisabled) return;

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('%cGetEditProfileDetails', 'color: #bada55', ProfileDetails);
      // }

      if (error.errorCode) {
        throw error.errorText;
      }

      runInAction(() => {
        this.profileDetails = ProfileDetails;
      });

      return ProfileDetails;
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    }
  };

  @action runAutoFetch = () => {
    this.autoFetch = true;
    this.fetch();
  };

  @action stopAutoFetch = () => {
    this.autoFetch = false;
    clearTimeout(this.autoFetchTimer);
  };
}
