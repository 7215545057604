import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import moment from 'moment';
import Modal from 'react-modal';

import { go } from '@/_utils/history';
import Spinner from '@/components/spinner';
import BetLabel from './BetLabel';
import { icons } from '@/components/Images';
import WaitModal from '@/components/wait-modal';
import RefreshModal from '@/components/refresh-modal';

export const MIN_ID = 3630
export const MAX_ID = 3672
export const FILTER_ID = 2600
export const USER_LEVEL = 3
export const FILTERED_OUT_COMPETITION_ID = 32
export const FILTERED_OUT_LEAGUE_ID_ID = 247

const MONDAY = 1;
const TRIAL_USER_LEVEL = 1
const FULL_MEMBER_LEVEL = 3

const SETTINGS = [{
  key: 'highValue',
  title: 'High Values'
}, {
  key: 'teamNews',
  title: 'Team News'
}, {
  key: 'isUrgent',
  title: 'Urgent Bet'
}]

@inject('store')
@observer
class BetForm extends Component {
  componentDidMount () {
    runInAction(() => {
      this.props.store.betslipStore.autoFetch = true;
    });
  }

  componentWillUnmount () {
    runInAction(() => {
      this.props.store.betslipStore.autoFetch = false;
    });
  }

  sendBet = id => async e => {
    e.preventDefault();
    const { user, unitLeft } = this.props.store.userStore;
    const { activeBet, sendBet} = this.props.store.betslipStore;
    const { picks, addPicksList } = this.props.store.mypicksStore;

    // const checkOnAdditionalSettings = () => {
    //   const emptyBetSettings = !activeBet.isUrgent && !activeBet.teamNews && !activeBet.highValue;
    //   return emptyBetSettings && !activeBet.isLive
    // }
  
    const checkOnRemainingUnitsStatus = () => {

      const hasNotApprovedBets = picks.some(pick => (
        ['in process', 'waiting'].includes(pick.gameStatus.toLowerCase())
      ));
  
      return !hasNotApprovedBets;
    }

    const addLocalPicksToStore = () => {
      const localPicks = JSON.parse(localStorage.getItem('localPicks'));

      addPicksList(localPicks || []);
    }

    const checkOnRemainingUnitsDate = () => {
      const gameDate = moment(activeBet.gameDate, 'DD/MM/YYYY HH:mm');

      const today = moment().isoWeekday();
      const followingMonday = today < MONDAY
        ? moment().isoWeekday(MONDAY)
        : moment().add(1, 'weeks').isoWeekday(MONDAY);
        
      const gameDateBeforeMonday = gameDate.isBefore(followingMonday, 'day');

      return gameDateBeforeMonday;
    }
  
    if (user.userlevel === FULL_MEMBER_LEVEL) {
      const remainingUnitsStatus = checkOnRemainingUnitsStatus();
      const remainingUnitsDate = checkOnRemainingUnitsDate();

      if (unitLeft <= 0 && remainingUnitsStatus && remainingUnitsDate) {
        this.toggleRejectedBetModal();
        return;
      }

      // const shouldCheckBetSettings = checkOnAdditionalSettings();
      // if (shouldCheckBetSettings) {
      //   this.toggleBetSettingsModal();
      //   return;
      // }
    }

    const idInWhiteList = (user.userId >= MIN_ID && user.userId <= MAX_ID) || user.userId === FILTER_ID
    if (user.userlevel === TRIAL_USER_LEVEL && !idInWhiteList) {
      const isSameBet = picks.some(bet => (
        activeBet.gameDate === bet.gameDate
          && activeBet.leagueName === bet.league
          && bet.team1 === activeBet.team1Name
          && bet.team2 === activeBet.team2Name
      ));

      if (isSameBet) {
        this.toggleBetLimitModal();
        return;
      }
    }

    if (user.userlevel === TRIAL_USER_LEVEL || user.userlevel === FULL_MEMBER_LEVEL) {
      const { gameDateFormated, isLive } = activeBet;

      if (isLive && parseInt(gameDateFormated, 10) > 75 && parseInt(gameDateFormated, 10) < 89) {
        this.toggleTimeLimitModal()
        return;
      }
    }

    await sendBet(id);
    await addLocalPicksToStore()
  };

  clearBet = id => e => {
    if (!id) return;
    this.props.store.betslipStore.clearBet(id);
  };

  handleInputChange = id => e => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'minOdds' && value) {
      const splitValue = value.split('.');
      let decimal = splitValue[1];
      if (typeof decimal === 'string') {
        if (decimal.length > 2) {
          value = `${splitValue[0]}.${decimal.substr(0, 2)}`
        }
      }
    }

    if (name === 'normalBet' && value) {
      SETTINGS.forEach(({ key }) => {
        this.props.store.betslipStore.updateBet(id, {
          [key]: false
        });
      });
    }

    if (name !== 'normalBet') {
      this.props.store.betslipStore.updateBet(id, {
        [name]: value
      });
    }
  };

  closeModal = () => {
    const { activeBet } = this.props.store.betslipStore;

    if (activeBet && activeBet.isShowModal) {
      this.props.store.betslipStore.closeModal(activeBet.id);

      this.props.store.betslipStore.removeBet(activeBet.id);
      // if (!this.props.store.betslipStore.tabs.length) {
      //   go('/games');
      // }
    }
  };

  toggleBetSettingsModal = () => {
    // const { activeBet } = this.props.store.betslipStore;

    // runInAction(() => {
    //   activeBet.isShowBetSettingsModal = !activeBet.isShowBetSettingsModal;
    // });
  };
  
  toggleRejectedBetModal = () => {
    const { activeBet } = this.props.store.betslipStore;

    runInAction(() => {
      activeBet.isShowRejectedBetModal = !activeBet.isShowRejectedBetModal;
    });
  };

  toggleBetLimitModal = () => {
    // const { activeBet } = this.props.store.betslipStore;

    // runInAction(() => {
    //   activeBet.isShowBetLimitModal = !activeBet.isShowBetLimitModal;
    // });
  };

  toggleTimeLimitModal = () => {
    const { activeBet } = this.props.store.betslipStore;

    runInAction(() => {
      activeBet.isShowTimeLimitModal = !activeBet.isShowTimeLimitModal;
    });
  };
  
  closeAutoFetchModal = () => {
    const { 
      tabs = [],
      activeBet,
      removeBet
    } = this.props.store.betslipStore;

    removeBet(activeBet.id);

    if (!(tabs.length - 1)) {
      go('/games');
    }
    
    if (activeBet && activeBet.isShowModalAutoFetch) {
      runInAction(() => {
        activeBet.isShowModalAutoFetch = false;
      });
    }
  };

  render () {
    const { bets, activeBet, modalRefreshOpened, modalWaitOpened } = this.props.store.betslipStore;
    const { user } = this.props.store.userStore;
    const { handleInputChange, clearBet } = this;
    const isSubmitting = activeBet ? activeBet.isSubmitting : false;
    const isPending = activeBet ? activeBet.isPending : false;
    const isShowModal = activeBet ? activeBet.isShowModal : false;
    const isShowModalAutoFetch = activeBet ? activeBet.isShowModalAutoFetch : false;
    const isShowBetSettingsModal = activeBet ? activeBet.isShowBetSettingsModal : false;
    const isShowRejectedBetModal = activeBet ? activeBet.isShowRejectedBetModal : false;
    const isShowBetLimitModal = activeBet ? activeBet.isShowBetLimitModal : false;
    const isShowTimeLimitModal = activeBet ? activeBet.isShowTimeLimitModal : false;
    const hasAccess = user && user.userlevel !== TRIAL_USER_LEVEL
    const isDisabled = !bets.length || isSubmitting || isPending;
    const emptyBetSettings = activeBet ? !activeBet.isUrgent && !activeBet.teamNews && !activeBet.highValue : true;

    const isValidMinOdds = activeBet ? !!parseFloat(activeBet.minOdds) : true;

    const title = activeBet ? <BetLabel id={activeBet.id}/> : null;
    const activeBetId = activeBet ? activeBet.id : undefined;

    const minOddsValue = activeBet ? activeBet.minOdds : '';
    const noteForTraderValue = activeBet ? activeBet.noteForTrader : '';

    const { unitLeft } = this.props.store.userStore;

    return (
      <form onSubmit={this.sendBet(activeBet && activeBet.id)} noValidate>
        {
          activeBet &&
          <div className="gamebet row">
            <div className="col-6">
              {activeBet.leagueName}: FP <strong>{activeBet.team1Name} - {activeBet.team2Name}</strong>
            </div>
            <div className="col-6 text-right">
              {activeBet.isLive ? <LiveBetTime bet={activeBet} /> : activeBet.gameDateFormated }
            </div>
          </div>
        }

        <div className="bet row">
          <div className="col-3">
            <div className="form-group new-pick-text"><span className="text-ellipsis">New Pick</span></div>
          </div>
          <div className="col-9">
            <div className="form-group">
              {title && <div className="new-pick-title">{title}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className={`form-group ${isValidMinOdds ? '' : ' invalid'}`}>
              <div className="form-text form-tex_ssm has-input">
                <label htmlFor="input-min-odds">Min. odds</label>
                <div className="input-text">
                  <input
                    id="input-min-odds"
                    type="number"
                    readOnly={isDisabled}
                    disabled={isDisabled || !hasAccess}
                    name="minOdds"
                    value={minOddsValue}
                    onChange={handleInputChange(activeBetId)}
                    onFocus={() => runInAction(() => activeBet.autoFetch = false)}
                  />
                </div>
              </div>
              {/* <input
                id="input-min-odds"
                type="number"
                className={`form-text form-tex_ssm ${minOddsValue ? 'has-text' : ''}`}
                readOnly={isDisabled}
                disabled={isDisabled}
                name="minOdds"
                value={minOddsValue}
                onChange={handleInputChange(activeBetId)}
              />
              <label className="float-label" htmlFor="input-min-odds">Min. odds</label> */}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <input
                id="input-notes"
                type="text"
                className={`form-text form-tex_ssm ${noteForTraderValue ? 'has-text' : ''}`}
                readOnly={isDisabled}
                disabled={isDisabled}
                name="noteForTrader"
                value={noteForTraderValue}
                onChange={handleInputChange(activeBetId)}
              />
              <label className="float-label" htmlFor="input-notes">Note for Trader</label>
            </div>
          </div>
        </div>

        <div className="setting-isUrgent checkboxes-list">
          <div className="form-checkbox form-checkbox_inline">
            <label className="checkbox-label">
              <span className="checkbox-label-text text-ellipsis">Urgent</span>
              <input
                type="checkbox"
                className="checkbox-input visually-hidden"
                disabled={isDisabled}
                name="isUrgent"
                checked={activeBet ? activeBet.isUrgent : false}
                onChange={handleInputChange(activeBetId)}
              />
              <span className="checkbox-label-outer">
                <span className="checkbox-label-inner"></span>
              </span>
            </label>
            <div className="info-container">
              <img src={icons.help} alt="send"/>
            </div>
          </div>

          <div className="setting-highValue form-checkbox form-checkbox_inline">
            <label className="checkbox-label">
              <span className="checkbox-label-text text-ellipsis">High Value</span>
              <input
                type="checkbox"
                className="checkbox-input visually-hidden"
                disabled={isDisabled}
                name="highValue"
                checked={activeBet ? activeBet.highValue : false}
                onChange={handleInputChange(activeBetId)}
              />
              <span className="checkbox-label-outer">
                <span className="checkbox-label-inner"></span>
              </span>
            </label>
            <div className="info-container">
              <img src={icons.help} alt="send"/>
            </div>
          </div>

          <div className="setting-teamNews form-checkbox form-checkbox_inline">
            <label className="checkbox-label">
              <span className="checkbox-label-text text-ellipsis">Team News</span>
              <input
                type="checkbox"
                className="checkbox-input visually-hidden"
                disabled={isDisabled}
                name="teamNews"
                checked={activeBet ? activeBet.teamNews : false}
                onChange={handleInputChange(activeBetId)}
              />
              <span className="checkbox-label-outer">
                <span className="checkbox-label-inner"></span>
              </span>
            </label>
            <div className="info-container">
              <img src={icons.help} alt="send"/>
            </div>
          </div>
        </div>

        <div className="bet row">
          <div className="col-6">
            <button
              type="button"
              className="btn btn-outline btn_block text-uppercase"
              disabled={isDisabled}
              onClick={clearBet(activeBetId)}
            >Clear</button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              className={`btn btn_primary btn_block text-uppercase ${isSubmitting ? 'in-processing' : ''}`}
              disabled={isDisabled || !isValidMinOdds}
            >
              Send bet
              <Spinner/>
            </button>
          </div>
        </div>
        <Modal
          isOpen={isShowModal}
          style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
        >
          <div className="modal pending-modal">
            <button type="button" className="btn-modal-close" onClick={this.closeModal}>X</button>
            <p>Pick Status: <b>PENDING</b></p>
            <p>Please do not bet the market until the bet is confirmed.</p>
            <p>You now have <b>{unitLeft}</b> units remaining for this week.</p>
          </div>
        </Modal>
        <Modal
          isOpen={isShowRejectedBetModal}
          style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
        >
          <div className="modal rejected-bet-modal">
            <button type="button" className="btn-modal-close" onClick={this.toggleRejectedBetModal}>X</button>
            <span className="rejected-bet-modal-title">
              <p>Sorry, You have no units remaining for this week.</p>
            </span>
            <div>
              <span className="rejected-bet-modal-text">
                <p>
                  <span>For more information please visit </span>
                  <a rel="noopener noreferrer" target="_blank" href="https://punteam.com/units-report">
                    <span>units report tab</span>
                  </a>
                </p>
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isShowBetLimitModal}
          style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
        >
          <div className="modal bet-limit-modal">
            <button type="button" className="btn-modal-close" onClick={this.toggleBetLimitModal}>X</button>
            <span className="bet-limit-modal-title">
              <p>Sorry, you're only allowed one pick per game.</p>
            </span>
          </div>
        </Modal>
        <Modal
          isOpen={isShowTimeLimitModal}
          style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
        >
          <div className="modal rejected-bet-modal">
            <button type="button" className="btn-modal-close" onClick={this.toggleTimeLimitModal}>X</button>
            <span className="rejected-bet-modal-title">
              <p>No bets allowed after the 75<sup>th</sup> minute</p>
            </span>
          </div>
        </Modal>
        <Modal
          isOpen={isShowBetSettingsModal}
          style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
        >
          <div className="modal bet-settings-modal">
            <button type="button" className="btn-modal-close" onClick={this.toggleBetSettingsModal}>X</button>
            <div className="bet-settings-title">
              <span><b>Do any of these apply to this bet?</b></span>
            </div>
            <div className="form-checkbox form-checkbox_inline bet-settings-container">
              <label className="checkbox-label checkbox-bet-settings">
                <span className="checkbox-label-text text-ellipsis">Normal Bet</span>
                <input
                  name="normalBet"
                  type="checkbox"
                  className="checkbox-input visually-hidden"
                  checked={emptyBetSettings}
                  onChange={handleInputChange(activeBetId)}
                />
                <span className="checkbox-label-outer">
                  <span className="checkbox-label-inner"></span>
                </span>
              </label>
              <div>
                {SETTINGS.map(({ title, key }) => (
                  <div key={key} className="form-checkbox form-checkbox_inline">
                    <label className="checkbox-label">
                      <span className="checkbox-label-text text-ellipsis">{title}</span>
                      <input
                        name={key}
                        type="checkbox"
                        className="checkbox-input visually-hidden"
                        checked={activeBet ? activeBet[key] : false}
                        onChange={handleInputChange(activeBetId)}
                      />
                      <span className="checkbox-label-outer">
                        <span className="checkbox-label-inner"></span>
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="bet-settings-notes-container">
              <label className="float-label" htmlFor="input-notes">Note for Trader</label>
              <input
                id="input-notes"
                type="text"
                className={`form-text form-tex_ssm ${noteForTraderValue ? 'has-text' : ''}`}
                readOnly={isDisabled}
                disabled={isDisabled}
                name="noteForTrader"
                value={noteForTraderValue}
                onChange={handleInputChange(activeBetId)}
              />
            </div>
            <div className="action-container">
              <button
                onClick={() => {
                  this.toggleBetSettingsModal()
                  this.props.store.betslipStore.sendBet(activeBet.id)
                }}
                className={`btn btn_primary btn_block text-uppercase ${isSubmitting ? 'in-processing' : ''}`}
                disabled={isDisabled || !isValidMinOdds}
              >
                Send bet
                <Spinner/>
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={!modalWaitOpened && !modalRefreshOpened && isShowModalAutoFetch}
          style={{ content: { overflow: 'visible' }, overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }}}
        >
          <div className="modal pending-modal">
            <button type="button" className="btn-modal-close" onClick={this.closeAutoFetchModal}>X</button>
            <p>Line has changed</p>
          </div>
        </Modal>
        <WaitModal opened={modalWaitOpened} />
        <RefreshModal opened={modalRefreshOpened}/>
      </form>
    );
  }
}

const LiveBetTime = ({ bet }) => (
  <>
    <span className="gamebet-status">In Running </span>
    <strong>
      <span className="gamebet-time"> {bet.minuteIR}' </span>
      <span className="gamebet-result"> {bet.team1Score}-{bet.team2Score}</span>
    </strong>
  </>
);

export default BetForm;
