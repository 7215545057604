import React from 'react';
import { toast } from 'react-toastify';

import store from '@/store';
import http from '@/_services/http.service';

import Notification from '@/components/notification';

const Notifications = window.cordova ?
  require('./cordova-notifications').default :
  require('./web-notifications').default;

export const sendTokenToServer = token => {
  console.log('%cNOTIFICATIONS_TOKEN', 'color:red', token);
  if (!isTokenSentToServer(token)) {
    console.log('Sending a token to the server...', token);

    http.post('/SetUserToken', { deviceToken: token, notificationType: window.cordova ? 1 : 2 });

    writeTokenToStorage(token);
  } else {
    console.log('Token already sent to server.');
  }
}

export const writeTokenToStorage = (token = null) => {
  if (token) {
    window.localStorage.setItem('firebaseToken', token);
  } else {
    window.localStorage.removeItem('firebaseToken');
  }
}

export const showUINotification = (data, openChat) => {
  console.log('showUINotification', data);

  if (store.chatStore.isGetForegroundNotifications && !store.chatStore.isOpen && !openChat) {
    const id = toast(<Notification {...data} />, {
      className: 'notification-toast',
      autoClose: false,
      onClose: () => {
        store.chatStore.removeNotification(id);
      }
    });
    store.chatStore.addNotification(id);
  }

  if (openChat) {
    store.chatStore.open();
  }
}

export const openChat = () => {
  store.chatStore.open();
};

const isTokenSentToServer = (token) => {
  return window.localStorage.getItem('firebaseToken') === token;
}

const notifications = new Notifications();
export default notifications;
