import React from 'react';

export const Shot = props => (
  <svg { ...props } version="1.1" id="Layer_1"
       xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }}>
    <path style={ { fill: '#ED4343' } } d="M273.872,344.912c-16.821-69.118-43.741-172.729-44.009-173.767c-1.159-4.463-5.553-7.3-10.102-6.514
c-0.556,0.097-56.09,9.164-83.626-31.807c-10.461-15.567-15.852-36.685-16.026-62.771c-0.034-4.987-4.17-8.971-9.162-8.812
c-1.213,0.04-30.122,1.201-61.346,22.187L3.924,114.124c-4.066,2.735-5.149,8.248-2.415,12.317l215.337,320.398
c2.409,3.586,7.051,4.916,10.995,3.147C249.046,440.521,288.337,404.327,273.872,344.912z"/>
    <path style={ { fill: '#C93F3F' } } d="M148.174,146.44c-21.516-18.599-27.877-48.072-28.066-76.388c-0.034-4.987-4.17-8.971-9.162-8.812
c-1.213,0.04-30.122,1.201-61.346,22.187L3.924,114.124c-4.066,2.735-5.149,8.248-2.415,12.317l82.864,123.293
C88.179,206.04,112.752,168.301,148.174,146.44z"/>
    <polygon style={ { fill: '#CC2929' } }
             points="204.167,213.194 98.991,230.11 137.449,287.336 219.105,274.202 "/>
    <circle style={ { fill: '#E2E2E2' } } cx="381.93" cy="239.4" r="130.07"/>
    <path style={ { fill: '#F2F2F2' } } d="M385.064,109.363c-42.174,17.91-71.753,59.709-71.753,108.415
c0,85.784,88.975,142.623,166.622,107.132C499.9,302.045,512,272.135,512,239.395C512,168.607,455.452,111.027,385.064,109.363z"/>
    <g>
      <path style={ { fill: '#D6D0D0' } } d="M372.212,202.354c1.113,0.559,2.297,0.825,3.464,0.825c2.829,0,5.554-1.559,6.91-4.259
l29.134-57.974c1.917-3.813,0.379-8.458-3.434-10.374c-3.813-1.917-8.457-0.379-10.374,3.434l-29.134,57.974
C366.861,195.793,368.399,200.438,372.212,202.354z"/>
      <path style={ { fill: '#D6D0D0' } } d="M475.96,218.631h-93.216c-4.267,0-7.727,3.459-7.727,7.727s3.46,7.727,7.727,7.727h93.216
c4.267,0,7.727-3.459,7.727-7.727S480.227,218.631,475.96,218.631z"/>
      <path style={ { fill: '#D6D0D0' } } d="M379.849,247.931c-1.867-3.837-6.492-5.433-10.329-3.568c-3.837,1.866-5.435,6.491-3.568,10.329
l38.227,78.574c1.865,3.833,6.484,5.437,10.329,3.568c3.837-1.867,5.435-6.491,3.568-10.329L379.849,247.931z"/>
      <path style={ { fill: '#D6D0D0' } } d="M342.899,250.101c-3.769-1.999-8.447-0.563-10.446,3.206l-35.768,67.459
c-2.73,5.149,1.024,11.349,6.82,11.349c2.768,0,5.446-1.493,6.833-4.109l35.768-67.459
C348.105,256.776,346.669,252.1,342.899,250.101z"/>
      <path style={ { fill: '#D6D0D0' } } d="M309.802,131.145c-4.31,2.878-8.438,6.005-12.364,9.364l37.963,62.872
c1.452,2.404,4.004,3.734,6.622,3.734c5.977,0,9.721-6.565,6.607-11.722C348.316,194.872,310.155,131.641,309.802,131.145z"/>
      <path style={ { fill: '#D6D0D0' } } d="M337.274,226.358c0-4.268-3.46-7.727-7.727-7.727h-76.032c-0.812,5.06-1.33,10.217-1.54,15.454
h77.572C333.814,234.085,337.274,230.626,337.274,226.358z"/>
    </g>
    <path style={ { fill: '#871313' } } d="M47.384,84.917c14.267,28.265,50.409,37.343,76.437,18.593c-6.516-27.429-0.7-34.28-6.436-39.796
	c-1.724-1.658-4.047-2.549-6.439-2.473C95.443,61.756,71.457,68.745,47.384,84.917z"/>
    <g>
      <path style={ { fill: '#EAE5E5' } } d="M129.911,296.276c-3.727,0-7.009-2.703-7.619-6.5c-0.678-4.214,2.188-8.179,6.402-8.857
l93.15-14.983c4.214-0.67,8.179,2.19,8.856,6.402c0.678,4.214-2.188,8.179-6.402,8.857
C124.636,297.225,130.882,296.276,129.911,296.276z"/>
      <path style={ { fill: '#EAE5E5' } } d="M110.68,267.664c-3.727,0-7.008-2.702-7.619-6.501c-0.678-4.214,2.188-8.179,6.401-8.856
l99.68-16.037c4.211-0.685,8.178,2.187,8.856,6.401c0.678,4.214-2.188,8.179-6.401,8.856l-99.68,16.037
C111.502,267.631,111.088,267.664,110.68,267.664z"/>
      <path style={ { fill: '#EAE5E5' } } d="M92.351,238.906c-3.727,0-7.009-2.703-7.619-6.501c-0.678-4.214,2.188-8.179,6.402-8.857
l111.075-17.867c4.212-0.675,8.178,2.189,8.856,6.402c0.678,4.214-2.188,8.179-6.402,8.857L93.587,238.807
C93.172,238.874,92.759,238.906,92.351,238.906z"/>
    </g>
    <path style={ { fill: '#444C51' } } d="M399.946,223.782l-19.098-33.079c-0.92-1.594-2.621-2.576-4.461-2.576H338.19
	c-1.84,0-3.541,0.982-4.461,2.576l-19.099,33.079c-0.92,1.594-0.92,3.558,0,5.151l19.099,33.081c0.92,1.594,2.621,2.576,4.461,2.576
	h38.197c1.84,0,3.541-0.982,4.461-2.576l19.098-33.081C400.867,227.34,400.867,225.376,399.946,223.782z"/>
    <path style={ { fill: '#384047' } } d="M448.962,316.912h-44.146l-22.072,38.23l8.091,14.015c27.177-1.837,52.063-12.021,72.117-28.012
L448.962,316.912z"/>
    <g>
      <path style={ { fill: '#444C51' } } d="M457.916,332.42l-8.953-15.508h-44.146l-7.845,13.587C416.844,336.5,437.946,337.087,457.916,332.42
z"/>
      <path style={ { fill: '#444C51' } } d="M464.585,226.358l22.073,38.232h22.895c1.6-8.152,2.447-16.575,2.447-25.195
c0-18.207-3.746-35.536-10.5-51.267h-14.842L464.585,226.358z"/>
      <path style={ { fill: '#444C51' } } d="M382.167,109.325L401.84,143.4h44.145l7.349-12.731
C432.898,117.221,408.448,109.373,382.167,109.325z"/>
    </g>
    <path style={ { fill: '#384047' } }
          d="M277.475,316.912c13.05,17.557,30.421,31.709,50.54,40.884l1.532-2.654l-22.073-38.23H277.475z"/>
    <g>
      <path style={ { fill: '#A51C1C' } } d="M218.687,272.639c5.062,20.726,24.604,34.107,45.244,32.029
c-15.779-63.119-33.846-132.674-34.068-133.523c-1.159-4.463-5.553-7.3-10.102-6.514c-0.254,0.042-12.065,1.97-27.43,0.101
L218.687,272.639z"/>
      <path style={ { fill: '#A51C1C' } } d="M3.924,114.124c-4.066,2.735-5.149,8.248-2.415,12.317l215.337,320.398
c2.409,3.586,7.051,4.916,10.995,3.147c0.46-0.203,5.787-2.238,13.415-8.196L15.713,106.202L3.924,114.124z"/>
    </g>
    <path style={ { fill: '#871313' } } d="M90.981,218.194L15.713,106.202l-11.789,7.922c-4.066,2.735-5.149,8.248-2.415,12.317l82.864,123.293
	C85.326,238.795,87.581,228.23,90.981,218.194z"/>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>
);
