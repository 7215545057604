import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { inject, observer } from 'mobx-react';
import * as Yup from 'yup';

import { go } from '@/_utils/history';
import Spinner from '@/components/spinner';

@inject('store')
@observer
class Login extends Component {
  render () {
    const { login } = this.props.store.authStore;

    return (
      <div className="auth-inner login-component">
        <Formik
          initialValues={{
            email: '',
            password: '',
            server_error: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Email is invalid')
              .required('Email is required'),
            password: Yup.string()
              .min(5, 'Password must be at least 5 characters')
              .required('Password is required')
          })}
          onSubmit={login}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <div className="form-group">
                <Field
                  className="form-text"
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete={window.cordova ? "username" : "off"}
                />
                <ErrorMessage className="form-message form-message_error" name="email" component="div"/>
              </div>
              <div className="form-group">
                <Field
                  className="form-text"
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete={window.cordova ? "current-password" : "off"}
                />
                <ErrorMessage className="form-message form-message_error" name="password" component="div"/>
              </div>
              <div className="form-group form-group_m0">
                <ErrorMessage className="form-message form-message_error form-message_top text-center" name="server_error" component="div"/>
                <button
                  className={`btn btn_primary btn_xl btn_block ${isSubmitting ? 'in-processing' : ''}`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <span className="text-ellipsis">Log In</span>
                  <Spinner/>
                </button>
              </div>
                <div className="text-right">
                  <span
                    className="auth-link"
                    onClick={() => !isSubmitting && go('/forgot-password')}
                  >
                    Forgot password
                  </span>
                </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

export default Login;
