import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

import config from '@/firebase-config';
import { sendTokenToServer, writeTokenToStorage, showUINotification, openChat } from './index';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
};

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export default class WebNotifications {
  static type = 'WebNotifications';

  async getToken () {
    if (!messaging) return;

    const hasPermission = await this.requestPermission();

    if (hasPermission) {
      try {
        return await messaging.getToken();
      } catch (error) {
        console.warn(error);
      }
    } else {
      return null;
    }
  }

  async requestPermission () {
    if (!messaging) return;

    const permission = await Notification.requestPermission();
    const hasPermission = permission === 'granted';

    return hasPermission;
  }

  grantPermission = this.requestPermission;

  async subscribe () {
    if (!messaging) return;

    const hasPermission = await this.requestPermission();

    if (!hasPermission) {
      // TODO: show message for user about important notifications for this app
      console.warn('It\'s important to allow notification\nfor get actual bets status');
      return;
    }

    try {
      const token = await this.getToken();
      sendTokenToServer(token);
    } catch (error) {
      console.warn(error);
      writeTokenToStorage(null);
    }

    this.onMessage = messaging.onMessage(async payload => {
      console.log('%cFOREGROUND MESSAGE', 'color:red', payload);
      showUINotification(payload.data || payload.notification);
    });

    this.onTokenRefresh = messaging.onTokenRefresh(async () => {
      try {
        const token = messaging.getToken();
        // Send Instance ID token to app server.
        sendTokenToServer(token);
      } catch (error) {
        console.warn('Unable to retrieve refreshed token', error);
      }
    });

    navigator.serviceWorker.addEventListener('message', this.onMessageFromSW);
  }

  onMessageFromSW = ({ data }) => {
    if (data && data.action === 'open_chat') {
      openChat();
    }
  };

  async unsubscribe () {
    if (!messaging) return;

    const firebaseToken = localStorage.getItem('firebaseToken');
    if (!firebaseToken) return;

    const token = await messaging.getToken();
    messaging.deleteToken(token);
    navigator.serviceWorker.removeEventListener('message', this.onMessageFromSW);

    // unsubscribe
    if (typeof this.onTokenRefresh === 'function') {
      this.onTokenRefresh();
      this.onTokenRefresh = null;
    }
    if (typeof this.onMessage === 'function') {
      this.onMessage();
      this.onMessage = null;
    }

    writeTokenToStorage(null);
  };
}
