import {action, computed, observable, reaction, runInAction} from 'mobx';
import moment from 'moment';
import uniqid from 'uniqid';
import http from '@/_services/http.service';
import {go} from '@/_utils/history';
import performance from '@/_services/perfomance';
import {getWebsocketData} from "@/helpers/websocket";
import {prepareGamesInfo} from "@/helpers/gamesHelper";

const TRIAL_USER_LEVEL = 1;

// const MIN_ID = 3262
// const MAX_ID = 3298

export default class BetslipStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  @observable autoFetch = false;
  autoFetchTimer = null;
  timeout = 3000;
  gameScore = null;

  reactionAutoFetch = reaction(
    () => this.autoFetch,
    isAutoFetch => {
      if (isAutoFetch) {
        this.fetchActiveBet();
      } else {
        clearTimeout(this.autoFetchTimer);
      }
    }
  );

  maxBetLength = 4;
  @observable showModal = false;
  @observable modalWaitOpened = false;
  @observable modalRefreshOpened = false;

  @observable bets = [];
  @observable activeBetId = null;

  @computed get tabs () {
    return this.bets.map(bet => ({
      label: bet.label,
      id: bet.id,
      isLive: bet.isLive,
      isPending: bet.isPending,
      isSelected: bet.id === this.activeBetId
    }));
  }

  @computed get activeBet () {
    return this.bets.find(bet => bet.id === this.activeBetId);
  }

  savePicksToStorage = ({ targetBet, line, betName }) => {
    const localPick = {
      pickId: uniqid(),
      league: targetBet.leagueName,
      team1: targetBet.team1Name,
      team2: targetBet.team2Name,
      gameDate: moment(targetBet.eventDateTime).format("DD/MM/YYYY HH:mm"),
      bet: `${betName} ${line} ${targetBet.minOdds}`,
      memberBet: {
        bet: betName,
        fh: "",
        line,
        odds: targetBet.minOdds,
      },
      traderBet: {
        bet: betName,
        fh: "",
        line,
        odds: targetBet.minOdds,
      },
      isLive: "",
      gameStatus: "Pending",
      betAmount: "",
      revenueRoi: "",
      isGraph: true,
    };
  
    const localPicks = JSON.parse(localStorage.getItem('localPicks'));
  
    const updatedPicks = localPicks !== null
      ? [...localPicks,  localPick]
      : [localPick];
    
    localStorage.setItem('localPicks', JSON.stringify(updatedPicks));
  }

  @action reset = () => {
    this.bets = [];
    this.activeBetId = null;
  };

  @action addBet = _bet => {
    console.log('%caddBet', 'color:lime', _bet);

    const id = generateBetId(_bet);
    const existingBet = this.bets.find(bet => bet.id === id);

    if (!existingBet) {
      if (this.bets.length >= this.maxBetLength) {
        this.showModal = true;
        return;
      }
      const { user } = this.rootStore.userStore

      this.bets.push(transformBet(_bet, user && user.userlevel))
    }

    this.activeBetId = id;
    go('/betslip');
  };

  @action removeBet = betId => {
    const targetBet = this.bets.find((bet = {}) => bet.id === betId);

    if (targetBet) {
      this.bets = this.bets.filter(bet => bet !== targetBet);
      if ((this.activeBetId === targetBet.id) && this.bets.length) {
        this.setSelectedTab(this.bets[0].id);
      }
    }
  };

  @action updateBet = (id, data) => {
    const targetBet = this.bets.find(bet => bet.id === id);
    if (targetBet.isPending) {
      return;
    }
    this.bets = this.bets.map(bet => {
      if (bet.id === id) {
        return {
          ...bet,
          ...data
        };
      }
      return bet;
    });
  };

  @action clearBet = id => {
    const targetBet = this.bets.find(bet => bet.id === id);
    if (!targetBet || targetBet.isSubmitting || targetBet.isPending) return;
    const { user } = this.rootStore.userStore
    this.updateBet(id, transformBet(targetBet._bet, user && user.userlevel));
  };

  @action setSelectedTab = id => {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      this.activeBetId = id;
    }
  };

  @action hideModal = () => {
    this.showModal = false;
  }

  @action sendBet = async betId => {
    await this.fetchActiveBet()
    const [gameId] = betId.split('/')
    const game = this.rootStore.gamesStore.bet.games.find(item => item.id === +gameId)
    const targetBet = this.bets.find(bet => bet.id === betId);
    if (targetBet.isPending) {
      return;
    }

    if (!targetBet) {
      return;
    }

    const updatedGame = game.picks[0]
    const { user } = this.rootStore.userStore
    const teamsScore = `${updatedGame.team1Score}-${updatedGame.team2Score}`
    // const idInWhiteList = user.userId >= MIN_ID && user.userId <= MAX_ID

    if (!this.gameScore && user.userlevel === TRIAL_USER_LEVEL && targetBet.isLive
      //  && !idInWhiteList
       ) {
      this.gameScore = teamsScore;

      runInAction(() => {
        this.modalWaitOpened = true;
        this.rootStore.gamesStore.bet.autoFetch = true;
      })
      setTimeout(() => {
        runInAction(() => {
          this.modalWaitOpened = false;
          this.rootStore.gamesStore.bet.autoFetch = false;
        })
        this.sendBet(betId)
      }, 60000);
      return;
    }

    if (this.gameScore && teamsScore !== this.gameScore) {
      runInAction(() => {
        this.modalRefreshOpened = true;
      })
      return;
    }
    this.gameScore = null;

    runInAction(() => {
      this.bets = this.bets.map(bet => {
        if (bet.id === betId) {
          return { ...bet, isSubmitting: true };
        }
        return bet;
      });
    })

    let betName = '';
    let line = 0;

    switch (targetBet.betKey) {
      case 'odds1Hcap':
        betName = 'home';
        line = targetBet.lineHcap;
        break;
      case 'odds2Hcap':
        betName = 'away';
        line = (0 - targetBet.lineHcap);
        break;
      case 'odds1Total':
        betName = 'over';
        line = targetBet.lineTotal;
        break;
      case 'odds2Total':
        betName = 'under';
        line = targetBet.lineTotal;
        break;
      // no default
    }

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebCreatePick');
      trace.start();
    }

    try {
      const { data, data: { error }} = await http.post('/WebCreatePick', { // eslint-disable-line no-unused-vars
        sportId: targetBet.sport,
        homeTeam: targetBet.team1Name,
        awayTeam: targetBet.team2Name,
        leagueName: targetBet.leagueName,
        leagueId: targetBet.leagueId,
        leagueNameId: targetBet.leagueNameId,
        gameDate: targetBet.eventDateTime,
        isLive: targetBet.isLive,
        score: targetBet.isLive ? `${targetBet.team1Score}-${targetBet.team2Score}` : '',
        isUrgent: targetBet.isUrgent,
        highValue: targetBet.highValue,
        teamNews: targetBet.teamNews,
        betGamePartId: targetBet.betGamePart,
        bet: betName[0].toUpperCase() + betName.slice(1),
        line: line,
        minOdds: targetBet.minOdds,
        notes: targetBet.noteForTrader,
        eventConnectionId: targetBet.eventsConnectionId,
        gameId: targetBet.gameId,
        onHalf: null // onHalf always is null
      });

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      this.savePicksToStorage({ targetBet, line, betName });

      await this.rootStore.userStore.fetch(); // update unitsleft

      if (process.env.NODE_ENV === 'development') {
        console.log('%cWebCreatePick', 'color:lime', data);
      }
      runInAction(() => {
        this.bets = this.bets.map(bet => {
          if (bet.id === betId) {
            return { ...bet, isPending: true, isShowModal: true };
          }
          return bet;
        });
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.error(error);
    } finally {
      runInAction(() => {
        this.bets = this.bets.map(bet => {
          if (bet.id === betId) {
            return { ...bet, isSubmitting: false };
          }
          return bet;
        });
      });
    }
  };

  @action closeModal = betId => {
    this.bets = this.bets.map(bet => {
      if (bet.id === betId) {
        return { ...bet, isShowModal: false };
      }
      return bet;
    });
  };

  @action fetchActiveBet = async () => {
    clearTimeout(this.autoFetchTimer);

    const bet = this.activeBet;
    console.log('fetchActiveBet', bet);

    if (!bet || !bet.autoFetch) {
      if (this.autoFetch) {
        this.autoFetchTimer = setTimeout(() => {
          this.fetchActiveBet();
        }, this.timeout); // eslint-disable-line eqeqeq
      }
      return;
    };

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebCreatePick');
      trace.start();
    }

    try {
      // const { data: { games, error }} = await http.post('/GamesByLeague', {
      //   sport: bet.sport,
      //   betGamePart: bet.betGamePart,
      //   market: bet.market,
      //   leagueNameId: bet.leagueNameId,
      //   gameId: bet.gameId
      // });

      // if (process.env.REACT_APP_ENV === 'production') {
      //   if (error.errorCode !== 0) {
      //     trace.putAttribute('errorCode', `${error.errorCode}`);
      //   }
      //   trace.stop();
      // }

      // if (!this.league) return;
      //
      // const sport = this.root.selectedSport.id;
      // const { league } = this;
      // try {

      // const websocketData = getWebsocketData()
      //   const {gamesWithOdds} = prepareGamesInfo({
      //     league, leagueEvents, websocketData, utcOffset
      //   })
      //   runInAction(() => {
      //     this.games = this.transformGames(gamesWithOdds, sport, league.market, league.leagueName);
      //   })
      // } catch (error) {
      //   console.warn(error);
      // } finally {
      //   if (this.autoFetch) {
      //     this.autoFetchTimer = setTimeout(() => {
      //       this.fetchPicks();
      //     }, this.timeout);
      //   }
      // }

      const utcOffset = moment().utcOffset() / 60;
      const leagueEvents = this.rootStore.gamesStore.leagueEvents || [];
      const league = {
        leagueId: bet.leagueId,
        leagueName: bet.leagueName,
        leagueNameId: bet.leagueNameId
      };

      const websocketData = getWebsocketData()
      const {gamesWithOdds} = prepareGamesInfo({
        league, leagueEvents, websocketData, utcOffset
      })
      const pick = gamesWithOdds.find(game => {
        return game.lineHcap == bet.lineHcap && game.lineTotal == bet.lineTotal; // eslint-disable-line eqeqeq
      });

      const { user } = this.rootStore.userStore
      runInAction(() => {
        if (pick && bet.autoFetch) {
          const minOdds = user && user.userlevel !== TRIAL_USER_LEVEL
          ? pick[bet.betKey] - 0.1
          : pick[bet.betKey]

          bet.minOdds = Number(minOdds).toFixed(2) < 1 ? 1 : Number(minOdds).toFixed(2);
        } else if (bet.autoFetch && !this.modalWaitOpened && !this.modalRefreshOpened) {
          bet.autoFetch = false;
          bet.isShowModalAutoFetch = true;
        }
      });
    } catch (error) {
      // if (process.env.REACT_APP_ENV === 'production') {
      //   trace.putAttribute('error', error.message);
      //   trace.stop();
      // }
      console.warn(error);
    } finally {
      if (this.autoFetch) {
        this.autoFetchTimer = setTimeout(() => {
          this.fetchActiveBet();
        }, this.timeout); // eslint-disable-line eqeqeq
      }
    }
  };
}

const generateBetLabel = bet => {
  switch (bet.betKey) {
    case 'odds1Total':
      return `${bet.team1Name} - ${bet.team2Name} Over ${bet.lineTotal}`;
    case 'odds2Total':
      return `${bet.team1Name} - ${bet.team2Name} Under ${bet.lineTotal}`;
    case 'odds1Hcap':
      return `${bet.team1Name} - ${bet.team2Name} Home ${bet.lineHcap}`;
    case 'odds2Hcap':
      return `${bet.team1Name} - ${bet.team2Name} Away ${0 - bet.lineHcap}`;

    //no default
  }
  return '';
};

const generateGameDate = bet => {
  switch (bet.market) {
    case 2:
    case '2':
      return bet.minuteIR;

    case 3:
    case '3':
      return bet.gameDate.split(' ')[1];

    case 8:
    case '8':
      return bet.gameDate;

    // no default
  }
  return '';
};

const generateBetId = bet => {
  return `${bet.gameId}/${bet.isCorners}/${bet.isLive}/${bet.leagueId}/${bet.betKey}/${bet.lineHcap}/${bet.lineTotal}/${bet[bet.betKey]}`;
};

const transformBet = (bet, userLevel) => {
  const minOdds = userLevel !== TRIAL_USER_LEVEL
  ? bet[bet.betKey] - 0.1
  : bet[bet.betKey];

  return {
    ...bet,
    _bet: bet,
    id: generateBetId(bet),
    label: generateBetLabel(bet),
    gameDateFormated: generateGameDate(bet),
    minOdds: Number(minOdds.toFixed(2)),
    isUrgent: false,
    highValue: false,
    teamNews: false,
    noteForTrader: '',
    isSubmitting: false,
    isPending: false,
    isShowModal: false,
    autoFetch: true,
    isShowModalAutoFetch: false,
    isShowBetSettingsModal: false,
    isShowRejectedBetModal: false,
    isShowBetLimitModal: false,
    isShowTimeLimitModal: false
  };
};
