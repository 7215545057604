import React from 'react';
import spinner from './spinner.svg';
import './spinner.scss';

const Spinner = props => (
  <div {...{ className: `spinner ${props.className || ''}` }}>
    <img src={spinner} alt="spinner" />
  </div>
);

export default Spinner;
