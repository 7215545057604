import { action, observable, computed } from 'mobx';

export default class AppSizeStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  /**
   * @observable
   */
  @observable AppSize = {};
  @observable BodySize = {};

  /**
   * @computed
   */
  @computed get height () {
    return this.AppSize.height;
  }
  @computed get width () {
    return this.AppSize.width;
  }
  @computed get heightBody () {
    return this.BodySize.height;
  }
  @computed get widthBody () {
    return this.BodySize.width;
  }

  /**
   * @action
   */
  @action update = (size, state = 'App') => {
    this[`${state}Size`] = size;
  };
}
