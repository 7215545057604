import moment from "moment";

const prepareOdds = (odd) => odd < 1 ? 1 : odd;

export const prepareGamesInfo = ({league, leagueEvents, websocketData, utcOffset = 0}) => {
    const eventsGames = []
    leagueEvents.forEach(v => {
        const event = websocketData[v.eventId]
        if (!event) return

        const gameOdds = [];
        let minute = null
        if (event.state && event.state.time && event.state.time.length) {
            switch (event.state.time[0]) {
                case '1h':
                    minute = Number(event.state.time[1]) > 45 ? '45+' : event.state.time[1]
                    break;
                case '2h':
                    minute = Number(event.state.time[1]) > 45 ? '90+' : 45 + Number(event.state.time[1])
                    break;
                default:
            }
        }
        const date = moment.utc(event.metadata.start_time).add(utcOffset, 'hours');
        const game = {
            betGamePart: 0,
            eventDateTime: date.format('YYYY-MM-DDTHH:mm:ss'),
            gameDate: date.format('DD/MM/YYYY HH:mm'),
            shortDate: date.format('DD/MM/YY | HH:mm'),
            gameId: v.gameId,
            gameTimestamp: date.unix(),
            isLive: ['in_running', 'running'].includes(event.metadata.ir_status),
            leagueId: league.leagueId,
            leagueNameId: league.leagueNameId,
            minuteIR: minute,
            team1Name: event.metadata.home,
            team1Score: event.state && event.state.score && event.state.score.length ? event.state.score[0] : null,
            team2Name: event.metadata.away,
            team2Score: event.state && event.state.score && event.state.score.length ? event.state.score[1] : null,
        }

        const odds = {
            Hcap: {},
            Total: {}
        }

        event.snapshot.forEach(item => {
            if (!odds[item.bet]) {
                odds[item.bet] = {}
            }
            odds[item.bet][item.line] = {
                line: item.line,
                odds1: prepareOdds(item.odds1),
                odds2: prepareOdds(item.odds2),
            }
        })

        odds['Total'] = Object.values(odds['Total']).sort((a, b) => b.line - a.line);
        odds['Hcap'] = Object.values(odds['Hcap']).sort((a, b) => b.line - a.line);
        const oddsLength = Math.max(odds['Total'].length, odds['Hcap'].length);

        for (let i = 0; i < oddsLength; i++) {
            gameOdds.push({
                ...game,
                lineHcap: odds['Hcap'][i] ? odds['Hcap'][i].line : null,
                odds1Hcap: odds['Hcap'][i] ? odds['Hcap'][i].odds1 : null,
                odds2Hcap: odds['Hcap'][i] ? odds['Hcap'][i].odds2 : null,
                lineTotal: odds['Total'][i] ? odds['Total'][i].line : null,
                odds1Total: odds['Total'][i] ? odds['Total'][i].odds1 : null,
                odds2Total: odds['Total'][i] ? odds['Total'][i].odds2 : null,
            })
        }
        eventsGames[event.metadata.event_id] = gameOdds
    })

    const gamesWithOdds = Object.values(eventsGames).reduce((result, current) => {
        return result.concat(current)
    }, [])

    return {gamesWithOdds, eventsGames}
}

export const prepareLeagueEvents = (games, league) => {
    if (!league) return [];

    return games
        .filter(v => v.leagueNameId === league.leagueNameId && v.market === league.market)
        .map(v => ({
            eventId: v.eventId,
            gameId: v.gameId
        }))
}