import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withSize } from 'react-sizeme';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthLayout from '@/layouts/AuthLayout';
import DashboardLoadable from '@/layouts/Dashboard/Loadable';
import NoConnection from '@/components/no-connection';

import '@/_utils/setup-modal';

@inject('store')
@observer
class App extends Component {
  componentDidMount () {
    this.props.store.authStore.checkAuthorize();
    this.props.store.appSizeStore.update(this.props.size);
  }

  componentDidUpdate () {
    this.props.store.appSizeStore.update(this.props.size);
  }

  render () {
    const { isSignedIn } = this.props.store.authStore;
    const { isOnline } = this.props.store.netStore;

    return (
      <div className="app">
        {isSignedIn ? <DashboardLoadable/> : <AuthLayout/>}
        <ToastContainer
          enableMultiContainer
          position={toast.POSITION.TOP_RIGHT}
          draggablePercent={20}
        />
        {isOnline ? null : <NoConnection/>}
      </div>
    );
  }
}

export default withSize({
  monitorWidth: true,
  monitorHeight: true,
  refreshRate: 32
})(App);
