import { action, observable, computed, runInAction } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';

import http from '@/_services/http.service';
import performance from '@/_services/perfomance';

const TRIAL_USER_LEVEL = 1

export default class ChatStore {
  constructor (rootStore) {
    this.rootStore = rootStore;

    if (!localStorage.getItem('isGetForegroundNotifications')) {
      localStorage.setItem('isGetForegroundNotifications', '1');
    }
  }

  _isDisabled = true;
  autoFetch = false;
  autoFetchTimer = null;
  autoFetchTimeout = 60 * 1000; // 60s

  @observable _unReadMsgsCount;
  get unReadMsgsCount () {
    return this._unReadMsgsCount;
  }
  set unReadMsgsCount (value) {
    this._unReadMsgsCount = value;
    if (window.FirebasePlugin) {
      console.log('window.FirebasePlugin', value);
      window.FirebasePlugin.setBadgeNumber(value);
    }
  }

  @observable isOpen;
  @observable messages = [];
  @observable isSending;

  @observable notifications = [];
  @observable isGetForegroundNotifications = Number(localStorage.getItem('isGetForegroundNotifications')) ? 1 : 0;

  @computed get chatMessages () {
    return this.messages
      .slice()
      .reverse();
  }

  @computed get hasNewMessage () {
    if (!this.messages.length) {
      return false;
    }
    return this.messages[0].isNew;
  }

  @action init = () => {
    this._isDisabled = false;

    this.unReadMsgsCount = 0;
    this.isOpen = false;
    this.messages = [];
    this.isSending = false;
    this.runAutoFetch();
  };

  @action reset = () => {
    this._isDisabled = true;
    this.stopAutoFetch();
  };

  @action checkAutoFetch = () => {
    const { mypicksStore, userStore } = this.rootStore

    if (this.isOpen) {
      this.autoFetchTimeout = 500; // 0.5s
      // this.autoFetchTimeout = 5000000;
    } else if (mypicksStore.hasActivePick) {
      this.autoFetchTimeout = 10 * 1000; // 10s
    } else {
      this.autoFetchTimeout = 60 * 1000; // 60s
    }

    if (userStore.user && userStore.user.userlevel !== TRIAL_USER_LEVEL) {
      this.fetchLastMessages();
    }
  };

  @action fetchLastMessages = async () => {
    clearTimeout(this.autoFetchTimer);

    if (this._isDisabled) return;

    let trace;

    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetLastChatMessagesMemory');
    }

    try {
      this.getUnReadMsgsCount();

      if (process.env.REACT_APP_ENV === 'production') {
        trace.start();
      }
      const { data: { chatMessages, error }} = await http.post('/GetLastChatMessagesMemory', { lastChatId: 0 });

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('%cGetLastChatMessagesMemory', 'color: #bada55', chatMessages);
      // }

      if (this._isDisabled) return;

      runInAction(() => {
        chatMessages.slice()/* .reverse() */.forEach(message => {
          if (!this.messages.some(m => m.id === message.id)) {
            this.messages.unshift({
              ...message,
              _formatedDate: moment(message.messageDate, 'DD/MM/YYYY').format('dddd, D MMMM YYYY')
            });
          }
        });
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    } finally {
      if (this._isDisabled) return;
      if (this.autoFetch) {
        this.autoFetchTimer = setTimeout(() => this.fetchLastMessages(), this.autoFetchTimeout);
      }
    }
  };

  @action sendMessage = async message => {
    if (this.isSending) return;

    this.isSending = true;

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetChatMessages');
      trace.start();
    }

    try {
      const { data, data: { error }} = await http.post('/SetChatMessages', { message }); // eslint-disable-line no-unused-vars

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('%cSetChatMessages', 'color: #bada55', data);
      }
      await this.fetchLastMessages();
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    } finally {
      if (this._isDisabled) return;
      runInAction(() => {
        this.isSending = false;
      });
    }
  }

  @action getUnReadMsgsCount = async () => {
    let trace;

    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetCountUnReadChatMessages');
      trace.start();
    }

    try {
      const { data: { unReadCount, error }} = await http.post('/GetCountUnReadChatMessages');

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('%cGetWebTemplate', 'color:lime', data);
      // }

      runInAction(() => {
        const unReadMsgsCount = Number(unReadCount);
        if (!isNaN(unReadMsgsCount)) {
          this.unReadMsgsCount = unReadMsgsCount;
        }
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    }
  };

  @action setAsRead = async message => {
    if (!message.isNew) {
      return;
    }

    let trace;
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetReadChatMessage');
      trace.start();
    }

    try {
      const { data: { error }} = await http.post('/SetReadChatMessage', { ids: message.id });

      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }

      setTimeout(() => {
        runInAction(() => {
          if (!this._isDisabled) {
            this.messages = this.messages.map(m => {
              if (m.id === message.id) {
                m.isNew = false;
              }
              return m;
            });
          }
        });
      }, 3000);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production') {
        trace.putAttribute('error', error.message);
        trace.stop();
      }
      console.warn(error);
    }
  };

  @action runAutoFetch = () => {
    this.autoFetch = true;
    this.checkAutoFetch();
  };

  @action stopAutoFetch = () => {
    this.autoFetch = false;
    clearTimeout(this.autoFetchTimer);
  };

  @action toggle = () => {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  @action open = () => {
    this.isOpen = true;
    this.checkAutoFetch();

    // clear all UI notifications if chet is opened
    this.clearNotifications();
  };

  @action close = () => {
    this.isOpen = false;
    this.checkAutoFetch();
  };

  @action addNotification = id => {
    this.notifications.push(id);
  };

  @action removeNotification = id => {
    this.notifications = this.notifications.filter(_id => _id !== id);
  };

  @action clearNotifications = () => {
    this.notifications.forEach(toast.dismiss);
    this.notifications = [];
  };

  @action toggleForegroundNotifications = state => {
    if (state === undefined) {
      this.isGetForegroundNotifications = this.isGetForegroundNotifications ? 0 : 1;
    } else {
      this.isGetForegroundNotifications = state ? 1 : 0;
    }
    localStorage.setItem('isGetForegroundNotifications', `${this.isGetForegroundNotifications}`);
  };
}
