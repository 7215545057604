import { observable, action, computed, runInAction } from 'mobx';
import { get as _get } from 'lodash';
import http from '@/_services/http.service';
import { 
  getSelectedMatch,
  getSoccerData,
  getColors,
  getShots,
  getScores,
  TEAM
} from '@/_utils/bet365';

export default class NewStatisticsStore {
  @observable newStatisticsTabs = [
    {
      id: 1,
      label: 'Soccer',
      value: 'soccer',
      isSelected: true
    },
    {
      id: 2,
      label: 'Match Stats',
      value: 'match-stats',
    },
    {
      id: 3,
      label: 'Match Events',
      value: 'match-events'
    }
  ];

  @observable soccerGames = [];

  @observable matchStats = {};
  
  @observable matchEvents = [];

  @observable eventId = null;

  @observable loading = false;

  @observable matchStatsLoading = false;

  @computed get selectedTab () {
    return this.newStatisticsTabs.find(item => item.isSelected);
  }

  @computed get soccerGamesLoading () {
    return this.loading && !Object.keys(this.matchStats).length;
  }

  @action setSelected = (prop, value) => {
    const items = _get(this, prop, []);
    items.forEach(item => item.isSelected = item === value);
  };

  @action switchLoading = (isInProcess) => {
    runInAction(() => {
      this.loading = isInProcess;
    });
  }

  @action callBetAPI = async (shouldRefreshGame = true) => {
    try {
      this.switchLoading(true);

      const { data } = await http.post('/GetStatisticsGames');
      const soccerGames = getSoccerData(data.result.results[0]);

      if (shouldRefreshGame && soccerGames.length > 0 && soccerGames[0].games) {
        const [firstGame] = soccerGames[0].games;
        await this.callGameStatistics({ eventId: this.eventId === null && firstGame ? firstGame.id : this.eventId });
      }

      runInAction(() => {
        this.soccerGames = soccerGames;
      });

      this.switchLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      this.switchLoading(false);
    }
  };

  callResultsAPI = async (eventId) => {
    try {
      const { data } = await http.post('/GetStatisticsResults', {
        event_id: eventId
      });

      return (data.result ? data.result.results : []).find(item => (
        parseInt(item.bet365_id, 10) === eventId
      ));
    } catch (error) {
      console.error("Error: ", error);
      return [];
    }
  };

  callEventInPlayAPI = async (eventId) => {
    try {
      const { data } = await http.post('/GetStatisticsEvents', {
        fi: eventId
      });
      
      return data.result ? data.result.results : [];
    } catch (error) {
      console.log("Error: ", error);
      return [];
    }
  };

  @action callGameStatistics = async ({ eventId, callback = () => {}, shouldShowLoading }) => {
    try {
      runInAction(() => {
        this.matchStatsLoading = shouldShowLoading;
      });

      const gamesResults = await this.callResultsAPI(eventId);
      const eventStats = await this.callEventInPlayAPI(eventId);

      if (gamesResults && eventStats) {
        const data = getSelectedMatch(gamesResults, eventStats, [0, 120]);
        
        const teamStats = (data.selectedEventStats[0] || []).filter(item => (
          item["type"] === TEAM
        ));

        const teamsScores = getScores(data.ss);
        const teamColors = getColors(teamStats);
        const teamsShots = getShots(data.stats.on_target, data.stats.off_target);

        const {
          possession_rt,
          attacks,
          dangerous_attacks,
          on_target,
          off_target,
          corners,
          yellowcards,
          redcards,
          penalties,
          goals
        } = data.stats;

        runInAction(() => {
          this.eventId = eventId;
          this.matchEvents = data.history;
          this.matchStats = {
            league: data.league,
            timer: data.timer,
            time: data.time,
            stats: data.stats,
            filteredHistory: data.filteredHistory,
            home: {
              ...data.home,
              primaryColor: teamColors.primaryColorHome,
              secondaryColor: teamColors.secondaryColorHome,
              shots: teamsShots.homeShots,
              score: teamsScores.homeScore,
              attacks: (attacks || [])[0],
              dangerousAttacks: (dangerous_attacks || [])[0],
              progression: (possession_rt || [])[0],
              onTarget: (on_target || [])[0],
              offTarget: (off_target || [])[0],
              corners: (corners || [])[0],
              yellowCards: (yellowcards || [])[0],
              redCards: (redcards || [])[0],
              penalties: (penalties || [])[0],
              goals: (goals || [])[0]
            },
            away: {
              ...data.away,
              primaryColor: teamColors.primaryColorAway,
              secondaryColor: teamColors.secondaryColorAway,
              shots: teamsShots.awayShots,
              score: teamsScores.awayScore,
              attacks: (attacks || [])[1],
              dangerousAttacks: (dangerous_attacks || [])[1],
              progression: (possession_rt || [])[1],
              onTarget: (on_target || [])[1],
              offTarget: (off_target || [])[1],
              corners: (corners || [])[1],
              yellowCards: (yellowcards || [])[1],
              redCards: (redcards || [])[1],
              penalties: (penalties || [])[1],
              goals: (goals|| [])[1]
            }
          }
        });
      
        await callback();
      }

      runInAction(() => {
        this.matchStatsLoading = false;
      });
        
    } catch (error) {
      runInAction(() => {
        this.matchStatsLoading = false;
      });
    }
  }
}; 